<template>
  <div id="app">
		<keep-alive>
			<router-view
					v-if="$route.meta.keepAlive"
			    transition
			    transition-mode="out-in"
			>
			</router-view>
		</keep-alive>
    <router-view
				v-if="!$route.meta.keepAlive"
        transition
        transition-mode="out-in"
    >
    </router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {

  },

}
</script>

<style>
html{
  font-size: 10px !important;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike,
strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td ,textarea,input { margin:0; padding:0;  }
address,cite,dfn,em,var, i {font-style:normal;}
body { line-height: 1.5; font-family:'Microsoft Yahei','simsun','arial','tahoma';  color: #333; }
table { border-collapse:collapse; border-spacing:0; }
h1, h2, h3, h4, h5, h6, th { font-size: 100%; font-weight: normal; }
button,input,select,textarea{font-size:100%;}
fieldset,img{border:0;}
a,
img {
  -webkit-touch-callout: none;
	display: block;
	width: 100%;
	height: 100%;
}
a,
a:active,
a:focus,
a:hover,
a:visited {text-decoration: none}
input[type=password],
input[type=text],
textarea {
  resize: none;
  outline: 0;
  -webkit-appearance: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: #fff
}
ul, ol { list-style: none; }
:focus{ outline:none;}
.clearfix{ clear: both; content: ""; display: block; overflow: hidden }
.clear{clear: both;}
.fl{ float: left; }
.fr{float: right;}
.flex-row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.wrap{
  width: 100%;
  height: auto;
  overflow: hidden;
}
h4, .h4, h5, .h5, h6, .h6, p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
h1{
  margin: 0 !important;
  padding: 0 !important;
}
p{
  margin-bottom: 1rem;
	font-size: 1.6rem;
}
#app {
  font-family: "PingFang SC", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
	background-color: #FFFFFF;
	
}
@media only screen and (max-width: 767px){
  html{
    font-size: 8px !important;
  }

}
.amap-marker-label{
  border: 1px solid transparent !important;
}
.width-100{
	width: 100%;
	height: auto;
	overflow: hidden;
}
.width-50{
	width: 50%;
	height: auto;
	overflow: hidden;
}
.content-width{
	width: 100%;
	box-sizing: border-box;
	padding: 0 8.333vw;
	height: auto;
	overflow: hidden;
}
.flex-row{
	display: flex;
	flex-direction: row;
}
.flex-col{
	display: flex;
	flex-direction: column;
}
.flex-center{
	justify-content: center;
	align-items: center;
}
.margin-bottom-4rem{
	margin-bottom: 4rem !important;
}
.margin-bottom-2rem{
	margin-bottom: 2rem !important;
}
.margin-bottom-1rem{
	margin-bottom: 1rem !important;
}
.img-box{
	width: 100%;
	height: auto;
	overflow: hidden !important;
}
.img-box>img{
	height: auto;
	cursor: pointer;
	transition: 0.5s;
}
.img-box:hover>img{
	transform: scale(1.1);
	
}
.swiper-pagination-bullet-active{
	background: #FFFFFF !important;
}
.swiper-pagination-bullet{
	width: 10px !important;
	height: 10px !important;
}
</style>
