import Vue from 'vue'
import App from './App.vue'

//引入路由
import VueRouter from 'vue-router'
import Routes from "./router.js"
import httpRequest from './request';
Vue.prototype.$http = httpRequest; // ajax请求方法
const router = new VueRouter({
  routes: Routes
})
//路由跳转之前监听守卫
router.beforeEach((to, from, next) => {
//  路由变化时更改页面title
  if(to.meta.title){
    document.title = to.meta.title
  }
	//判断新闻列表页是否需要缓存
	if(to.path == '/news'){
		if(from.path =='/newsDetails'){
			// 如果是从新闻详情页面返回的新闻列表页面则不刷新页面
			to.meta.keepAlive = true;
		}else {
			// 否则是从其他页面跳转到新闻列表页面的则刷新页面
			to.meta.keepAlive = false; 
		}
	}
  next()
})

//路由跳转之后监听守卫
router.afterEach(() => {
  //页面跳转后滚动条重置至顶部
  window.scrollTo(0,0);
})

//解决跳转当前页面报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

//引入工具类
import utils from './utils';
Vue.use(utils, app)

//引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
Vue.use(ElementUI);

//引入wow.js动画效果库
import wow from 'wowjs'
import 'wowjs/css/libs/animate.css'
Vue.prototype.$wow=wow

//引入swiper轮播图组件
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'


Vue.config.productionTip = false

let vueMain = new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')

export default vueMain;

