<template>
	<div class="wrap">
		<!--    header-->
		<gw-header page="/greatDeeds" ref="gwheader"></gw-header>
		<div class="width-100 margin-bottom-4rem">
			<img :src="util.getImageUrl('deeds_02.jpg')" />
		</div>
		<div class="content-width" style="margin: 4rem 0; position: relative;">
			<div class="year-swiper margin-bottom-4rem">
				<div class="swiper-container">
					<div class="year-line"></div>
				  <div class="swiper-wrapper">
				    <div class="swiper-slide" v-for="(item, index) in yearList" :key="index">
				      <!-- <img :src="util.getImageUrl(item)" /> -->
							<div class="year-content">
								<span>{{item}}</span>
								<div></div>
							</div>
				    </div>
				  </div>
				</div>
				<!-- 导航按钮 -->
				<div class="swiper-button-prev"></div>
				<div class="swiper-button-next"></div>
			</div>
			<div class="content-width" v-loading="loading" v-if="dataList.length != 0">
				<el-row :gutter="200" v-for="(item, index) in dataList" :key="index" type="flex" align="middle" class="block-flex" style="border-bottom: 1px solid #E6E6E6; margin-bottom: 2rem">
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
						<div class="margin-bottom-2rem content-text">
							<h3 v-html="item.notes_title"></h3>
							<p v-html="item.notes_content"></p>
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
						<div class="margin-bottom-2rem img-box data-img">
							<img :src="item.notes_img" />
						</div>
					</el-col>
				</el-row>
			</div>
			<el-empty v-else description="当年暂无事记!"></el-empty>
		</div>
		<!--    footer-->
		<gw-footer></gw-footer>
	</div>
</template>

<script>
	import gwHeader from '../../components/header.vue';
	import gwFooter from '../../components/footer.vue';
	import countTo from 'vue-count-to';
	import {chgryNotes} from '../../api.js';
	export default {
		name: 'greatDeeds',
		components: {
			gwHeader,
			gwFooter,
			countTo 
		},
		data() {
			return {
				yearList:[],
				dataList: [],
				currentIndex: 0,
				loading: false,
				year: null,
			}
		},
		created() {
			this.getYearList();
		},
		methods: {
			// 获取年份列表
			async getYearList(){
				this.loading = true;
				const { data } = await chgryNotes('get');
				if(data){
					this.yearList = data.all_years || [];
					this.getDataList(this.yearList[this.currentIndex]);
					this.$nextTick(() => {
						this.initSwiper();
					})
				}else{
					this.yearList = [];
					this.dataList = [];
				}
				this.loading = false;
			},
			// 获取大事记数据
			async getDataList(year){
				this.year = year;
				this.loading = true;
				const { data } = await chgryNotes('post', year);
				var values = Object.values(data);
				this.dataList = values[0] || [];
				this.loading = false;
			},
			// 初始化swiper
			initSwiper(){
				let _this = this;
				var mySwiper = new Swiper ('.swiper-container', {
					// autoplay: 2000,
					slidesPerView: 6,
					slideToClickedSlide: true,
					centeredSlides: true,
					// slidesOffsetAfter: ,
					slideActiveClass : 'current-slide-active',
					speed: 500,
				  // loop: true,
				  // 如果需要分页器
				  pagination: '.swiper-pagination',
				  // 如果需要前进后退按钮
				  nextButton: '.swiper-button-next',
				  prevButton: '.swiper-button-prev',
				  onSlideChangeStart: function(swiper){
						_this.getDataList(_this.yearList[swiper.activeIndex]);
					}
				
				})
			},
		},
		mounted() {
			
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	.data-img{
		width: 80%;
		height: 22rem;
		margin: 0 auto;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.year-swiper{
		width: 100%;
		height: auto;
		overflow: hidden;
		position: relative;
	}
	.swiper-container{
	  height: 10rem;
	  overflow: hidden;
	  width: calc(100% - 10rem);
		.year-line{
			width: calc(100% - 6rem);
			height: 2px;
			box-sizing: border-box;
			background-color: #E5E5E5;
			margin-left: 3rem;
			transform: translateY(8.1rem);
		}
	  .swiper-wrapper{
	    .swiper-slide{
	      width: 100%;
	      height: 100%;
	      box-sizing: border-box;
				transform: translateX(-250%);
				.year-content{
					width: 100%;
					height: 10rem;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					span{
						font-size: 3.2rem;
						line-height: 6rem;
						color: #888888;
					}
					div{
						width: 1rem;
						height: 1rem;
						border-radius: 50%;
						background-color: #DCDCDC;
					}
				}
	    }
	  }
	}
	.current-slide-active{
		.year-content{
			span{
				font-size: 4.4rem !important;
				color: #4C4C4C !important;
			}
			div{
				background-color: #FFFFFF !important;
				border: 2px solid #CD0000;
				transform: scale(1.5);
			}
		}
	}
	.swiper-button-prev{
	  // left: 2rem !important;
	  background-image: url("https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/icons/left.png");
	  background-size: 5rem 5rem;
	  top: 7.5rem;
		width: 5rem;
		height: 5rem;
		border-radius: 50%;
		background-color: #BBBBBB;
	}
	.swiper-button-next{
	  // right: 0rem !important;
	  background-image: url("https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/icons/right.png");
	  background-size: 5rem 5rem;
	  top: 7.5rem;
		width: 5rem;
		height: 5rem;
		border-radius: 50%;
		background-color: #BBBBBB;
	}
	.content-text {
		box-sizing: border-box;
		// padding-left: 5rem;
		h1{
			font-size: 3rem;
			color: #333;
			line-height: 6rem;
		}
		h2 {
			font-size: 3rem;
			color: #333;
		}

		h3 {
			font-size: 3rem;
			color: #CA9700;
			
			margin-bottom: 1rem;
		}

		p {
			font-size: 1.6rem;
			color: #666;
			line-height: 3rem;
		}
		.right-icon{
			width: 4rem;
			margin-top: 1rem;
		}
	}

	//  xs  小屏手机设备
	@media only screen and (max-width: 767px) {
		.block-flex {
			display: block;
			height: auto;
			overflow: hidden;
		}
		.swiper-slide{
			.year-content{
				span{
					font-size: 1.6rem !important;
				}
			}
		}
		.current-slide-active{
			.year-content{
				span{
					font-size: 2.4rem !important;
				}
			}
		}
		
	}

	//  sm  手机设备
	@media only screen and (min-width: 768px) {
		.block-flex {
			display: block;
			height: auto;
			overflow: hidden;
		}
		.swiper-slide{
			.year-content{
				span{
					font-size: 1.6rem !important;
				}
			}
		}
		.current-slide-active{
			.year-content{
				span{
					font-size: 2.4rem !important;
				}
			}
		}
	}

	// md  平板设备
	@media only screen and (min-width: 992px) {
		.block-flex {
			display: flex;
		}
		.swiper-slide{
			.year-content{
				span{
					font-size: 3.2rem !important;
				}
			}
		}
		.current-slide-active{
			.year-content{
				span{
					font-size: 4.4rem !important;
				}
			}
		}
	}

	// lg  一般电脑设备
	@media only screen and (min-width: 1200px) {
		.block-flex {
			display: flex;
		}
		.swiper-slide{
			.year-content{
				span{
					font-size: 3.2rem !important;
				}
			}
		}
		.current-slide-active{
			.year-content{
				span{
					font-size: 4.4rem !important;
				}
			}
		}
	}

	// xl  屏幕较大设备
	@media only screen and (min-width: 1920px) {
		.block-flex {
			display: flex;
		}
		.swiper-slide{
			.year-content{
				span{
					font-size: 3.2rem !important;
				}
			}
		}
		.current-slide-active{
			.year-content{
				span{
					font-size: 4.4rem !important;
				}
			}
		}
	}
</style>
