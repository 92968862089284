<template>
	<div class="wrap">
		<!--    header-->
		<gw-header page="/brand" ref="gwheader"></gw-header>
		<div class="content-width" style="margin: 4rem 0; overflow: initial !important;">
			<el-row :gutter="150">
				<el-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" v-for="(item, index) in brandList" :key="index">
					<div class="img-box images-wrap" style="overflow: initial !important;">
						<img @click="jumpPage(item.page, item.type)" :src="util.getImageUrl(item.imgUrl)" />
					</div>
				</el-col>
				
			</el-row>
		</div>
		<!--    footer-->
		<gw-footer></gw-footer>
	</div>
</template>

<script>
	import gwHeader from '../../components/header.vue';
	import gwFooter from '../../components/footer.vue';
	export default {
		name: 'brand',
		components: {
			gwHeader,
			gwFooter
		},
		data() {
			return {
				brandList: [
					{
						imgUrl: "brand_03.jpg",
						page: "/",
						type: "inside"
					},
					{
						imgUrl: "brand_05_new1.jpg",
						page: "/congerzhuangProduct",
						type: "inside"
					},
					{
						imgUrl: "brand_07.jpg",
						page: "/junxiaobaoProduct",
						type: "inside"
					},
					{
						imgUrl: "brand_09.jpg",
						page: "",
						type: "default"
					},
					{
						imgUrl: "brand_15.jpg",
						page: "",
						type: "default"
					},
					{
						imgUrl: "brand_16.png",
						page: "/ticuiProduct",
						type: "inside"
					},
					{
						imgUrl: "brand_17.jpg",
						// page: "http://www.momwow.com.cn/index.html",
						// type: "outside"
						page: "",
						type: "default"
					},
					{
						imgUrl: "brand_18.jpg",
						page: "http://www.gateon.cn/#/", 
						type: "outside"
					},
				],
			}
		},
		methods: {
			// 跳转页面
			jumpPage(page, type) {
				if (type === 'inside') {
					this.util.jumpPage(page);
				}else if(type === 'outside'){
					this.util.jumpExternalPage(page);
				}
			},
		},
		mounted() {

		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	.content-text {
		box-sizing: border-box;
		// padding-left: 5rem;
		h1{
			font-size: 4rem;
			color: #333;
			line-height: 6rem;
		}
		h2 {
			font-size: 4rem;
			color: #333;
		}

		h3 {
			font-size: 2.3rem;
			color: #333;
			line-height: 3.5rem;
			margin: 0;
		}

		p {
			font-size: 1.6rem;
			color: #666;
			line-height: 2.5rem;
			span{
				font-size: 2.5rem;
				color: #333333;
				line-height: 4rem;
			}
		}
		.right-icon{
			width: 4rem;
			margin-top: 1rem;
		}
	}
	.manager-content{
		p{
			font-size: 1.5rem;
			color: #333333;
			margin: 0;
			line-height: 2.5rem;
		}
		.right-icon{
			width: 4rem;
			margin-top: 1rem;
		}
	}
	.images-wrap{
		img{
			height: auto !important;
		}
	}
	//  xs  小屏手机设备
	@media only screen and (max-width: 767px) {
		.block-flex {
			display: block;
		}
		.images-wrap{
			height: 25vw;
		}
	}

	//  sm  手机设备
	@media only screen and (min-width: 768px) {
		.block-flex {
			display: block;
		}
		.images-wrap{
			height: 25vw;
		}
	}

	// md  平板设备
	@media only screen and (min-width: 992px) {
		.block-flex {
			display: flex;
		}
		.images-wrap{
			height: 15vw;
		}
	}

	// lg  一般电脑设备
	@media only screen and (min-width: 1200px) {
		.block-flex {
			display: flex;
		}
		.images-wrap{
			height: 15vw;
		}
	}

	// xl  屏幕较大设备
	@media only screen and (min-width: 1920px) {
		.block-flex {
			display: flex;
		}
		.images-wrap{
			height: 15vw;
		}
	}
</style>
