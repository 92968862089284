<template>
	<div class="wrap">
		<!--    header-->
		<gw-header page="/managementLayer" ref="gwheader"></gw-header>
		<div class="content-width" style="margin: 4rem 0;">
			<el-row :gutter="50" type="flex" align="middle" class="block-flex">
				<el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
					<div class="margin-bottom-4rem img-box">
						<img :src="util.getImageUrl('manage_03.jpg')" />
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
					<div class="content-text margin-bottom-4rem">
						<h3>集团创始人/董事长：涂醉桃</h3>
						<h3>上海晨冠健康科技集团有限公司</h3>
						<img class="right-icon" :src="util.getIconUrl('manage-right-icon.jpg')" />
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6" v-for="(item, index) in peopleList" :key="index">
					<div class="margin-bottom-4rem manager-content">
						<div class="img-box" style="margin-bottom: 1rem;">
							<img :src="util.getImageUrl(item.imgUrl)" />
						</div>
						
						<p>{{item.name}}</p>
						<p>{{item.feat}}</p>
						<img class="right-icon" :src="util.getIconUrl('manage-right-icon.jpg')" />
					</div>
				</el-col>
				
			</el-row>
		</div>
		<!--    footer-->
		<gw-footer></gw-footer>
	</div>
</template>

<script>
	import gwHeader from '../../components/header.vue';
	import gwFooter from '../../components/footer.vue';
	export default {
		name: 'managementLayer',
		components: {
			gwHeader,
			gwFooter
		},
		data() {
			return {
				peopleList: [
					{
						name: "涂华优先生",
						imgUrl: "manage_10.jpg",
						feat: "集团副总裁",
					},
					{
						name: "张艳杰女士",
						imgUrl: "manage_12.jpg",
						feat: "集团常务副总裁",
					},
					{
						name: "陆健文先生",
						imgUrl: "manage_14.jpg",
						feat: "技术运营官CTO",
					},
					{
						name: "孙婧女士",
						imgUrl: "manage_16.jpg",
						feat: "集团副总裁",
					},
					{
						name: "陈衍璟先生",
						imgUrl: "manage_22.jpg",
						feat: "集团副总裁",
					},
					{
						name: "李鼎旗先生",
						imgUrl: "manage_23.jpg",
						feat: "集团副总裁",
					}
				],
			}
		},
		methods: {
			
		},
		mounted() {

		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	.content-text {
		box-sizing: border-box;
		// padding-left: 5rem;
		h1{
			font-size: 4rem;
			color: #333;
			line-height: 6rem;
		}
		h2 {
			font-size: 4rem;
			color: #333;
		}

		h3 {
			font-size: 2.3rem;
			color: #333;
			line-height: 3.5rem;
			margin: 0;
		}

		p {
			font-size: 1.6rem;
			color: #666;
			line-height: 2.5rem;
			span{
				font-size: 2.5rem;
				color: #333333;
				line-height: 4rem;
			}
		}
		.right-icon{
			width: 4rem;
			margin-top: 1rem;
		}
	}
	.manager-content{
		p{
			font-size: 1.5rem;
			color: #333333;
			margin: 0;
			line-height: 2.5rem;
		}
		.right-icon{
			width: 4rem;
			margin-top: 1rem;
		}
	}

	//  xs  小屏手机设备
	@media only screen and (max-width: 767px) {
		.block-flex {
			display: block;
		}
	}

	//  sm  手机设备
	@media only screen and (min-width: 768px) {
		.block-flex {
			display: block;
		}
	}

	// md  平板设备
	@media only screen and (min-width: 992px) {
		.block-flex {
			display: flex;
		}
	}

	// lg  一般电脑设备
	@media only screen and (min-width: 1200px) {
		.block-flex {
			display: flex;
		}
	}

	// xl  屏幕较大设备
	@media only screen and (min-width: 1920px) {
		.block-flex {
			display: flex;
		}
	}
</style>
