<template>
  <section class="gt-pagination">
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      
      :page-size="pageSize"
      :layout="layout"
      :total="total"
    >
    </el-pagination>
  </section>
</template>

<script>
export default {
  name:'gtPagination',
  props: {
    total: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    layout: {
      type: String,
      default: 'total, prev, pager, next, jumper',
    },
  },
  methods:{
    handleSizeChange(val) {
      let obj = {currentPage: 1, pageSize: val}
      this.$emit('updatePagination', obj)
    },
    handleCurrentChange(val) {
      let obj = {currentPage: val, pageSize: this.pageSize}
      this.$emit('updatePagination', obj)
    },
  }
}
</script>
<style lang="scss" scoped>
.gt-pagination{
  display: inline-block;
  width: calc(100%);
  // margin-right: 20px;
  .el-pagination{
    margin-top: 15px;
    text-align: center;
  }
}

</style>

