<template>
	<div class="wrap">
		<!--    header-->
		<gw-header page="/joinUs" ref="gwheader"></gw-header>
		<div class="width-100 join-banner">
			<img :src="util.getImageUrl('join-us_02.jpg')" />
			<div class="banner-content">
				<h1 class="margin-bottom-4rem">人才，是晨冠最宝贵的资产</h1>
				<p>我们有深厚的产业积累,有多元化的文化氛围,包容的工作环境,扁平化的管理体系</p>
				<p class="margin-bottom-4rem">在这里,你能遇到更多并肩同行的伙伴,我们也会为你提供全方位的福利支持,更能帮助你的职业发展充满无限可能</p>
				<h3>加入我们吧 !</h3>
				<h3>世界将因我们而更精彩!</h3>
			</div>
			<!-- <div class="swiper-container">
			  <div class="swiper-wrapper">
			    <div class="swiper-slide" v-for="(item, index) in bannerList" :key="index">
			      <img :src="util.getImageUrl(item)" />
			    </div>
			
			  </div>
			  <div class="swiper-button-prev"></div>
			  <div class="swiper-button-next"></div>
			</div> -->
		</div>
		
		<!--    footer-->
		<gw-footer></gw-footer>
	</div>
</template>

<script>
	import gwHeader from '../../components/header.vue';
	import gwFooter from '../../components/footer.vue';
	export default {
		name: 'joinUs',
		components: {
			gwHeader,
			gwFooter,
		},
		data() {
			return {
				bannerList: [
					'join-us_02.jpg',
					'join-us_02.jpg',
					'join-us_02.jpg',
				],
			}
		},
		methods: {
			
		},
		mounted() {
			new Swiper ('.swiper-container', {
				autoplay: 2000,
				speed: 1500,
			  loop: true,
			  // 如果需要分页器
			  pagination: '.swiper-pagination',
			  // 如果需要前进后退按钮
			  nextButton: '.swiper-button-next',
			  prevButton: '.swiper-button-prev',
			  // 如果需要滚动条
			  // scrollbar: '.swiper-scrollbar',
			  //自动切换海报
			  // autoplay: {
			  //   delay: 3000,//时间 毫秒
			  //   disableOnInteraction: false,//用户操作之后是否停止自动轮播默认true
			  // },
			})
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	.join-banner{
		width: 100%;
		height: auto;
		overflow: hidden;
		position: relative;
		img{
			display: block;
			width: 100%;
			height: auto;
			object-fit: cover;
		}
	}
	.banner-content{
		width: 54%;
		height: 32rem;
		position: absolute;
		left: 23%;
		top: 50%;
		margin-top: -16rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    // border-radius: 1rem;
		h1{
			font-size: 3.6rem;
			color: #FFFFFF;
		}
		h3{
			font-size: 3rem;
			color: #FFFFFF;
		}
		p{
			font-size: 2rem;
			color: #FFFFFF;
			margin: 0;
			line-height: 3rem;
		}
	}
	.swiper-container{
	  height: auto;
	  overflow: hidden;
	  width: 100%;
	  .swiper-wrapper{
	    .swiper-slide{
	      width: 100%;
	      height: 100%;
	      box-sizing: border-box;
	      
	    }
	  }
	}
	.swiper-button-prev{
	  left: 3rem !important;
	  background-image: url("https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/icons/left.png");
	  background-size: 6rem 6rem;
	  top: calc(50% - 3rem);
		width: 6rem;
		height: 6rem;
	}
	.swiper-button-next{
	  right: 3rem !important;
	  background-image: url("https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/icons/right.png");
	  background-size: 6rem 6rem;
	  top: calc(50% - 3rem);
		width: 6rem;
		height: 6rem;
	}
	.content-text {
		h1{
			font-size: 4rem;
			color: #333;
			line-height: 6rem;
		}
		h2 {
			font-size: 4rem;
			color: #333;
		}

		h3 {
			font-size: 3rem;
			color: #333;
			line-height: 5rem;
		}

		p {
			font-size: 1.6rem;
			color: #666;
			line-height: 2.5rem;
			span{
				font-size: 2.5rem;
				color: #333333;
				line-height: 4rem;
			}
		}
	}

	//  xs  小屏手机设备
	@media only screen and (max-width: 767px) {
		.block-flex {
			display: block;
		}
		.join-banner{
			img{
				height: 60rem;
			}
		}
    .banner-content{
      width: 98%;
      height: auto;
      padding: 2rem 20%;
      box-sizing: border-box;
      left: 1%;
      top: 35%;
      h1{
        font-size: 2.6rem;
        text-align: center;
      }
      h3{
        font-size: 2.4rem;
      }
    }
	}

	//  sm  手机设备
	@media only screen and (min-width: 768px) {
		.block-flex {
			display: block;
		}
		.join-banner{
			img{
				height: 60rem;
			}
		}
    .banner-content{
      width: 98%;
      height: auto;
      padding: 2rem 20%;
      box-sizing: border-box;
      left: 1%;
      top: 35%;
      h1{
        font-size: 2.6rem;
        text-align: center;
      }
      h3{
        font-size: 2.4rem;
      }
    }
	}

	// md  平板设备
	@media only screen and (min-width: 992px) {
		.block-flex {
			display: flex;
		}
		.join-banner{
			img{
				height: auto;
			}
		}
    .banner-content{
      width: 98%;
      height: auto;
      padding: 2rem 20%;
      box-sizing: border-box;
      left: 1%;
      top: 35%;
      h1{
        font-size: 2.6rem;
        text-align: center;
      }
      h3{
        font-size: 2.4rem;
      }
    }
	}

	// lg  一般电脑设备
	@media only screen and (min-width: 1200px) {
		.block-flex {
			display: flex;
		}
		.join-banner{
			img{
				height: auto;
			}
		}
    .banner-content{
    	width: 54%;
    	height: 32rem;
    	position: absolute;
    	left: 23%;
    	top: 50%;
    	margin-top: -16rem;
    	display: flex;
    	flex-direction: column;
    	justify-content: center;
    	align-items: center;
      background-color: rgba(0, 0, 0, 0.3);
      // border-radius: 1rem;
      padding: 0;
    	h1{
    		font-size: 3.6rem;
    		color: #FFFFFF;
    	}
    	h3{
    		font-size: 3rem;
    		color: #FFFFFF;
    	}
    	p{
    		font-size: 2rem;
    		color: #FFFFFF;
    		margin: 0;
    		line-height: 3rem;
    	}
    }
	}

	// xl  屏幕较大设备
	@media only screen and (min-width: 1920px) {
		.block-flex {
			display: flex;
		}
		.join-banner{
			img{
				height: auto;
			}
		}
    .banner-content{
    	width: 54%;
    	height: 32rem;
    	position: absolute;
    	left: 23%;
    	top: 50%;
    	margin-top: -16rem;
    	display: flex;
    	flex-direction: column;
    	justify-content: center;
    	align-items: center;
      background-color: rgba(0, 0, 0, 0.3);
      padding: 0;
      // border-radius: 1rem;
    	h1{
    		font-size: 3.6rem;
    		color: #FFFFFF;
    	}
    	h3{
    		font-size: 3rem;
    		color: #FFFFFF;
    	}
    	p{
    		font-size: 2rem;
    		color: #FFFFFF;
    		margin: 0;
    		line-height: 3rem;
    	}
    }
	}
</style>
