<template>
	<div class="wrap">
		<!--    header-->
		<gw-header page="/researchInstitute" ref="gwheader"></gw-header>
		<div class="width-100 margin-bottom-4rem">
			<img :src="util.getImageUrl('science_02.jpg')" />
		</div>
		<div class="width-100 flex-row flex-center" style="margin: 4rem 0;">
			<div class="title-left">
				<img :src="util.getImageUrl('science_05.jpg')" />
			</div>
			<div class="title-right">
				<h1>晨冠健康科学研究院</h1>
				<h3>CHG Institute of Health Sciences</h3>
			</div>
		</div>
		<div class="content-text content-width">
			<p>晨冠健康科学研究院是由上海晨冠健康科技集团与上海交通大学健康传播发展中心等海内外知名院校共同成立的集科研、检测、培训、成果转化为一体的科研机构。晨冠集团20年来始终秉承“致力国人健康事业，科技成就生活之美”的理念，与上海交大健康传播发展中心强强联合，汇集了国内外各行各业知名专家学者，共同对生命科学、人体健康、益生菌功能食品、美容营养品、药食同源产品等领域开展广泛研究与探索，致力为广大用户提供高端健康产品和服务，为国人健康事业做出努力和贡献！</p>
		</div>
		
		<!--    footer-->
		<gw-footer></gw-footer>
	</div>
</template>

<script>
	import gwHeader from '../../components/header.vue';
	import gwFooter from '../../components/footer.vue';
	export default {
		name: 'researchInstitute',
		components: {
			gwHeader,
			gwFooter
		},
		data() {
			return {
				brandList: [
					{
						imgUrl: "brand_03.jpg",
					},
					{
						imgUrl: "brand_05.jpg",
					},
					{
						imgUrl: "brand_07.jpg",
					},
					{
						imgUrl: "brand_09.jpg",
					},
					{
						imgUrl: "brand_15.jpg",
					},
					{
						imgUrl: "brand_16.jpg",
					},
					{
						imgUrl: "brand_17.jpg",
					},
					{
						imgUrl: "brand_18.jpg",
					},
				],
			}
		},
		methods: {
			
		},
		mounted() {

		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	.title-left{
		width: 10rem;
		margin-right: 1rem;
	}
	.title-right{
		h1{
			font-size: 3.5rem;
			color: #333;
			line-height: 6rem;
			margin: 0;
			text-align: center;
		}
		h3{
			font-size: 2.3rem;
			color: #333;
			line-height: 3.5rem;
			margin: 0;
			text-align: center;
		}
	}
	.content-text {
		box-sizing: border-box;
		// padding-left: 5rem;
		h1{
			font-size: 4rem;
			color: #333;
			line-height: 6rem;
		}
		h2 {
			font-size: 4rem;
			color: #333;
		}

		h3 {
			font-size: 2.3rem;
			color: #333;
			line-height: 3.5rem;
			margin: 0;
		}

		p {
			font-size: 1.6rem;
			color: #666;
			line-height: 2.5rem;
			margin-bottom: 4rem;
		}
		
	}

	//  xs  小屏手机设备
	@media only screen and (max-width: 767px) {
		.block-flex {
			display: block;
		}
		.images-wrap{
			height: 25vw;
		}
	}

	//  sm  手机设备
	@media only screen and (min-width: 768px) {
		.block-flex {
			display: block;
		}
		.images-wrap{
			height: 25vw;
		}
	}

	// md  平板设备
	@media only screen and (min-width: 992px) {
		.block-flex {
			display: flex;
		}
		.images-wrap{
			height: 15vw;
		}
	}

	// lg  一般电脑设备
	@media only screen and (min-width: 1200px) {
		.block-flex {
			display: flex;
		}
		.images-wrap{
			height: 15vw;
		}
	}

	// xl  屏幕较大设备
	@media only screen and (min-width: 1920px) {
		.block-flex {
			display: flex;
		}
		.images-wrap{
			height: 15vw;
		}
	}
</style>
