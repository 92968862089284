<template>
	<div class="wrap">
		<!--    header-->
		<gw-header page="/news" ref="gwheader"></gw-header>
		<div class="content-width" style="margin: 2rem 0;">
			<div class="margin-bottom-2rem big-title">
				<h3>新闻动态</h3>
			</div>
			<el-row :gutter="50">
				<el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8" v-for="(item, index) in dataList" :key="index">
					<div class="main-content" @click="util.jumpPage('/newsDetails', { id: item.id})">
						<div class="news-images">
							<img :src="item.news_cover_img" />
						</div>
						<h3>{{item.news_title || '--'}}</h3>
						<p>{{item.news_cover_content || '--'}}</p>
						<div class="time-content">
							<span>{{util.dateFormat(item.create_time)}}</span>
							<img :src="util.getIconUrl('next.png')" />
						</div>
					</div>
				</el-col>
			</el-row>
			<gt-pagination class="margin-bottom-2rem" :total="total" :currentPage="currentPage" :pageSize="pageSize" @updatePagination="pagination_event" />

		</div>
		<!--    footer-->
		<gw-footer></gw-footer>
	</div>
</template>

<script>
	import gwHeader from '../../components/header.vue';
	import gwFooter from '../../components/footer.vue';
	import gtPagination from '../../components/gt-pagination';
	import { chgryNews } from '../../api.js';
	export default {
		name: 'news',
		components: {
			gwHeader,
			gwFooter,
			gtPagination
		},
		data() {
			return {
				dataList: [],
				total: 0,
				currentPage: 1,
				pageSize: 6,
			}
		},
		created() {
			this.getDataList();
		},
		methods: {
			// 获取新闻动态列表
			async getDataList(){
				const loading = this.$loading({
					lock: true,
					text: '加载中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				const { data } = await chgryNews(this.currentPage);
				if(data.length != 0){
					this.dataList = data || [];
					this.total = data ? data[0].num : 0;
				}else{
					this.dataList = [];
					this.total = 0;
				}
				loading.close();
			},
			// 分页
			pagination_event(val){
				this.pageSize = val.pageSize;
				this.currentPage = val.currentPage;
				this.getDataList();
			},
		},
		mounted() {

		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	.main-content{
		background-color: #F6F7F9;
		box-sizing: border-box;
		width: 100%;
		height: auto;
		overflow: hidden;
		margin-bottom: 4rem;
		.news-images{
			width: 100%;
			height: 30rem;
			overflow: hidden;
			img{
				display: block;
				object-fit:cover;
				transition: 0.5s;
				cursor: pointer;
			}
		}
		.news-images:hover>img{
			transform: scale(1.1);
		}
		h3{
			width: 90%;
			margin: 2rem auto 0;
			font-size: 2.3rem;
			color: #333;
			line-height: 3.5rem;
			height: 7rem;
			overflow: hidden; //超出的文本隐藏
			text-overflow: ellipsis; //溢出用省略号显示
			display: -webkit-box;
			-webkit-line-clamp: 2; // 超出多少行
			-webkit-box-orient: vertical;
			cursor: pointer;
		}
		p{
			width: 90%;
			margin: 2rem auto 2rem;
			font-size: 1.6rem;
			color: #666;
			line-height: 2.5rem;
			height: 7.5rem;
			overflow: hidden; //超出的文本隐藏
			text-overflow: ellipsis; //溢出用省略号显示
			display: -webkit-box;
			-webkit-line-clamp: 3; // 超出多少行
			-webkit-box-orient: vertical;
			cursor: pointer;
			
		}
		.time-content{
			width: 90%;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			border-top: 1px solid #E6E7E9;
			padding: 2rem 0;
			span{
				cursor: pointer;
				font-size: 1.8rem;
				color: #A2A2A3;
			}
			img{
				cursor: pointer;
				width: 8px;
				height: 16px;
				vertical-align: middle;
			}
		}
	}
	.big-title{
		align-items: center;
		justify-content: space-between;
		h3{
			font-size: 2.6rem;
			color: #333333;
			margin: 0;
		}
		span{
			font-size: 1.6rem;
			color: #888888;
			padding: 10px 20px;
			border: 2px solid #888888;
			cursor: pointer;
		}
	}

	//  xs  小屏手机设备
	@media only screen and (max-width: 767px) {
		.block-flex {
			display: block;
		}
	}

	//  sm  手机设备
	@media only screen and (min-width: 768px) {
		.block-flex {
			display: block;
		}
	}

	// md  平板设备
	@media only screen and (min-width: 992px) {
		.block-flex {
			display: flex;
		}
	}

	// lg  一般电脑设备
	@media only screen and (min-width: 1200px) {
		.block-flex {
			display: flex;
		}
	}

	// xl  屏幕较大设备
	@media only screen and (min-width: 1920px) {
		.block-flex {
			display: flex;
		}
	}
</style>
