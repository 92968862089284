/*
 * @Autor: Mark
 * @Description: 公共网络请求
 * @Date: 2022-03-28 09:14:11
 * @LastEditors: Mark
 * @LastEditTime: 2022-04-29 15:51:00
 */
import axios from 'axios'
import { MessageBox, Message } from 'element-ui'

// create an axios instance
const service = axios.create({
  baseURL: "http://showapi.chgry.com",
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  },
  timeout: 60000,
})

// 请求拦截
service.interceptors.request.use(
  config => {
    
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// 响应拦截
service.interceptors.response.use(
  response => {
    // const res = response.data;
		
    // if(res.success === false){
    //   if(res.errCode === 11000){
    //     //登录失效处理
    //     MessageBox.confirm('登录失效，请重新登陆!', '登录失效', {
    //       confirmButtonText: '重新登录',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     }).then(() => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.reload()
    //       })
    //     })
    //   }else{
    //     //接口报错处理
    //     console.log("错误信息：", res);
    //     Message({
    //       message: res.message,
    //       type: 'error',
    //       duration: 3 * 1000
    //     })
    //   }
    // }
    return response;
  },
  error => {
    console.log('报错信息：' + error)
    Message({
      message: "接口请求失败！",
      type: 'error',
      duration: 3 * 1000
    })
    return Promise.reject(error);
  }
)

export default service
