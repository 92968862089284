export const productList = [{
    name: "菁粹",
    pageTitle: "菁粹",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/qingcui-product-bigImg-new.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/qingcui-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/qingcui-right-icon.png",
    subTitle: "1-3段幼儿配方奶粉",
    subTitleColor: "#003A8F",
    fontColor: "#DFBC34",
    tagList: [
        '进口新西兰纯净奶源',
        '特含新一代OPO结构脂',
        '多重权威有机认证',
    ],
    bigTxt: null,
    smallTxtList: [
        '世界卫生组织（WHO）',
        '与联合国儿童基金会（UNICEF）',
        '在大量科学研究的基础上，',
        '建议哺乳妈妈坚持哺乳24个月以上',
    ],
    detailsList: [
        'qingcui_02_new.jpg',
        // 'qingcui_03.jpg',
        // 'qingcui_04.jpg',
        // 'qingcui_05.jpg',
        // 'qingcui_06.jpg',
        // 'qingcui_07.jpg',
        // 'qingcui_08.jpg',
        // 'qingcui_09.jpg',
        // 'qingcui_10.jpg',
        // 'qingcui_11.jpg',
        // 'qingcui_12.jpg',
        // 'qingcui_13.jpg',
        // 'qingcui_14.jpg',
        // 'qingcui_15.jpg',
        // 'qingcui_16.jpg',
        // 'qingcui_17.jpg',
        // 'qingcui_18.jpg',
        // 'qingcui_19.jpg',
    ],
},
{
    name: "安宝素",
    pageTitle: "安宝素",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/anbaosu-product-bigImg-new.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/anbaosu-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/anbaosu-right-icon.png",
    subTitle: "1-3段幼儿配方奶粉",
    subTitleColor: "#003A8F",
    fontColor: "#DFBC34",
    tagList: [
        '进口新西兰纯净奶源',
        '特含9亿活性益生菌',
        '多重权威有机认证',
    ],
    bigTxt: null,
    smallTxtList: [
        '世界卫生组织（WHO）',
        '与联合国儿童基金会（UNICEF）',
        '在大量科学研究的基础上，',
        '建议哺乳妈妈坚持哺乳24个月以上',
    ],
    detailsList: [
        'anbaosu_02_new.jpg',
        // 'anbaosu_03.jpg',
        // 'anbaosu_04.jpg',
        // 'anbaosu_05.jpg',
        // 'anbaosu_06.jpg',
        // 'anbaosu_07.jpg',
        // 'anbaosu_08.jpg',
        // 'anbaosu_09.jpg',
        // 'anbaosu_10.jpg',
        // 'anbaosu_11.jpg',
        // 'anbaosu_12.jpg',
        // 'anbaosu_13.jpg',
        // 'anbaosu_14.jpg',
        // 'anbaosu_15.jpg',
        // 'anbaosu_16.jpg',
    ],
},
{
    name: "美可多",
    pageTitle: "美可多",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/meikeduo-product-bigImg.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/meikeduo-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/meikeduo-right-icon.png",
    subTitle: "1-3段幼儿配方奶粉",
    subTitleColor: "#644397",
    fontColor: "#E6CB65",
    tagList: [
        '进口新西兰纯净奶源',
        '科学配比  免疫配方',
        '多重权威有机认证',
    ],
    bigTxt: null,
    smallTxtList: [
        '世界卫生组织（WHO）',
        '与联合国儿童基金会（UNICEF）',
        '在大量科学研究的基础上，',
        '建议哺乳妈妈坚持哺乳24个月以上',
    ],
    detailsList: [
        // 'meikeduo_02.jpg',
        'meikeduo_03.jpg',
        'meikeduo_04.jpg',
        'meikeduo_05.jpg',
        'meikeduo_06.jpg',
        'meikeduo_07.jpg',
        'meikeduo_08.jpg',
        'meikeduo_09.jpg',
        'meikeduo_10.jpg',
        'meikeduo_11.jpg',
        'meikeduo_12.jpg',
        'meikeduo_13.jpg',
        'meikeduo_14.jpg',
        'meikeduo_15.jpg',
        'meikeduo_16.jpg',
        'meikeduo_17.jpg',
        'meikeduo_18.jpg',
    ],
},
{
    name: "儿童粉",
    pageTitle: "儿童粉",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/ertongfen-product-bigImg.png",
    logoImgUrl: null,
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/ertongfen-right-icon.png",
    subTitle: "聪尔壮儿童配方奶粉<br/>专为3-6岁儿童研制",
    subTitleColor: "#FCA503",
    fontColor: "#DFBC34",
    tagList: [
        '进口新西兰纯净奶源',
        '特含黄金免疫三因子',
        '多重权威有机认证',
    ],
    bigTxt: null,
    smallTxtList: [
        '3岁后，是孩子飞速成长的黄金时期，',
        '孩子的身体各项机能都发育非常迅速，',
        '对营养的需求也更多 。',
    ],
    detailsList: [
        // 'ertongfen_02.jpg',
        'ertongfen_03.jpg',
        'ertongfen_04.jpg',
        'ertongfen_05.jpg',
        'ertongfen_06.jpg',
        'ertongfen_07.jpg',
        'ertongfen_08.jpg',
        'ertongfen_09.jpg',
        'ertongfen_10.jpg',
        'ertongfen_11.jpg',
        'ertongfen_12.jpg',
        'ertongfen_13.jpg',
        'ertongfen_14.jpg',
    ],
},
{
    name: "学生粉",
    pageTitle: "学生粉",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/xueshengfen-product-bigImg.png",
    logoImgUrl: null,
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/xueshengfen-right-icon.png",
    subTitle: "聪尔壮学生配方奶粉<br/>专为6-12岁学生研制",
    subTitleColor: "#003A8F",
    fontColor: "#DFBC34",
    tagList: [
        '进口新西兰纯净奶源',
        '益智新科技  全面促进眼脑发育',
        '多重权威有机认证',
    ],
    bigTxt: null,
    smallTxtList: [
        '中小学生正处于生长发育迅速阶段，',
        '对能量和营养的需求量相对高于成年人，',
        '充足的营养是其智力、体格发育',
        '乃至一生健康的保障。',
    ],
    detailsList: [
        // 'xueshengfen_02.jpg',
        'xueshengfen_03.jpg',
        'xueshengfen_04.jpg',
        'xueshengfen_05.jpg',
        'xueshengfen_06.jpg',
        'xueshengfen_07.jpg',
        'xueshengfen_08.jpg',
        'xueshengfen_09.jpg',
        'xueshengfen_10.jpg',
        'xueshengfen_11.jpg',
        'xueshengfen_12.jpg',
        'xueshengfen_13.jpg',
        'xueshengfen_14.jpg',
        'xueshengfen_15.jpg',
        'xueshengfen_16.jpg',
        'xueshengfen_17.jpg',
    ],
},
{
    name: "太空1号肠道健康款",
    pageTitle: "菌小宝太空1号肠道健康款",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/junxiaobao-product-bigImg.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/1000yi-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/1000yi-right-icon.png",
    subTitle: "太空1号肠道健康款",
    subTitleColor: "#00C3B2",
    fontColor: "#00C3B2",
    tagList: [
        '每瓶添加1000亿CFU活性益生菌',
        '全球甄选10株优质菌株',
        '10株益生菌+4种益生元，纯净配方0添加',
        '太空充氮锁鲜黑科技，助力益生菌活性持久待机',
        '独立创意小瓶包装，携带更方便，好吃更好玩',
    ],
    bigTxt: "",
    smallTxtList: [],
    detailsList: [
        // 'junxiaobao100_02.jpg',
        'junxiaobao100_03.jpg',
        'junxiaobao100_04.jpg',
        'junxiaobao100_05.jpg',
        'junxiaobao100_06.jpg',
        'junxiaobao100_07.jpg',
        'junxiaobao100_08.jpg',
        'junxiaobao100_09.jpg',
        'junxiaobao100_10.jpg',
    ],
},
{
    name: "太空2号抗幽护胃款",
    pageTitle: "菌小宝太空2号抗幽护胃款款",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/500yi-product-bigImg.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/500yi-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/500yi-right-icon.png",
    subTitle: "太空2号抗幽护胃款",
    subTitleColor: "#059DE4",
    fontColor: "#059DE4",
    tagList: [
        '每瓶添加1000亿CFU活性益生菌',
        '全球甄选10株优质菌株',
        '4大专利菌株给胃粘膜披上一层防护衣',
        '12大益生菌5大黄金益生元，纯净配方0添加',
        '太空充氮锁鲜黑科技，助力益生菌活性持久待机',
        '独立创意小瓶包装，携带更方便，好吃更好玩'
    ],
    bigTxt: "",
    smallTxtList: [],
    // smallTxtList: ['0蔗糖 0脂肪 0香精  0色素  0乳粉  0防腐剂'],
    detailsList: [
        // 'junxiaobao500_02.jpg',
        'junxiaobao500_03.jpg',
        'junxiaobao500_04.jpg',
        'junxiaobao500_05.jpg',
        'junxiaobao500_06.jpg',
        'junxiaobao500_07.jpg',
        'junxiaobao500_08.jpg',
        'junxiaobao500_09.jpg',
        'junxiaobao500_10.jpg',
        'junxiaobao500_11.jpg',
        'junxiaobao500_12.jpg',
        'junxiaobao500_13.jpg',
    ],
},
{
    name: "太空5号体重管理款",
    pageTitle: "菌小宝太空5号体重管理款",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/500yishoushen-product-bigImg.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/500yishousheng-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/500yishoushen-right-icon.png",
    subTitle: "太空5号体重管理款",
    subTitleColor: "#E1345E",
    fontColor: "#E1345E",
    tagList: [
        '每瓶添加1000亿CFU活性益生菌',
        '全球甄选10株优质菌株',
        '10株益生菌+4种益生元，纯净配方0添加',
        '太空充氮锁鲜黑科技，助力益生菌活性持久待机',
        '独立创意小瓶包装，携带更方便，好吃更好玩'
    ],
    bigTxt: "",
    smallTxtList: [],
    detailsList: [
        // 'tizhongguanli_02.jpg',
        'tizhongguanli_03.jpg',
        'tizhongguanli_04.jpg',
        'tizhongguanli_05.jpg',
        'tizhongguanli_06.jpg',
        'tizhongguanli_07.jpg',
        'tizhongguanli_08.jpg',
        'tizhongguanli_09.jpg',
        'tizhongguanli_10.jpg',
        'tizhongguanli_11.jpg',
        'tizhongguanli_12.jpg',
        'tizhongguanli_13.jpg',
    ],
},
{
    name: "太空6号解酒护肝款",
    pageTitle: "菌小宝太空6号解酒护肝款",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/500yijiankang-product-bigImg.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/500yijiankang-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/500yijiankang-right-icon.png",
    subTitle: "太空6号解酒护肝款",
    subTitleColor: "#E24278",
    fontColor: "#E24278",
    tagList: [
        '每瓶添加1000亿CFU活性益生菌',
        '全球甄选10株优质菌株',
        '10株益生菌+4种益生元，纯净配方0添加',
        '太空充氮锁鲜黑科技，助力益生菌活性持久待机',
        '独立创意小瓶包装，携带更方便，好吃更好玩',
    ],
    bigTxt: "",
    smallTxtList: [],
    detailsList: [
        // 'nvxingjiankang_02.jpg',
        'nvxingjiankang_03.jpg',
        'nvxingjiankang_04.jpg',
        'nvxingjiankang_05.jpg',
        'nvxingjiankang_06.jpg',
        'nvxingjiankang_07.jpg',
        'nvxingjiankang_08.jpg',
        'nvxingjiankang_09.jpg',
        'nvxingjiankang_10.jpg',
        'nvxingjiankang_11.jpg',
        'nvxingjiankang_12.jpg',
    ],
},
{
    name: "太空10号儿童守护款",
    pageTitle: "菌小宝太空10号儿童守护款",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/200yi-product-bigImg.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/200yi-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/200yi-right-icon.png",
    subTitle: "太空10号儿童守护款",
    subTitleColor: "#FAC561",
    fontColor: "#FAC561",
    tagList: [
        '每瓶添加200亿CFU鲜活益生菌',
        '全球甄选10株优质菌株，特别添加4株婴幼儿可食用菌株',
        '10株益生菌+5种益生元，纯净配方0添加',
        '太空充氮锁鲜黑科技，助力益生菌活性持久待机',
        '独立创意小瓶包装，携带更方便，好吃更好玩'
    ],
    bigTxt: "",
    // smallTxtList: ['0蔗糖 0脂肪 0香精  0色素  0乳粉  0防腐剂'],
    smallTxtList: [],
    detailsList: [
        // 'ertong_02.jpg',
        'ertong_03.jpg',
        'ertong_04.jpg',
        'ertong_05.jpg',
        'ertong_06.jpg',
        'ertong_07.jpg',
        'ertong_08.jpg',
        'ertong_09.jpg',
        'ertong_10.jpg',
        'ertong_11.jpg',
        'ertong_12.jpg',
    ],
},
{
    name: "太空17号情绪管理款",
    pageTitle: "菌小宝太空17号情绪管理款",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/100yi-product-bigImg.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/100yi-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/100yi-right-icon.png",
    subTitle: "太空17号情绪管理款",
    subTitleColor: "#E68744",
    fontColor: "#E68744",
    tagList: [
        '每瓶添加1000亿CFU鲜活益生菌',
        '特别添加GABA（Y-氨基丁酸）晚安主力菌株',
        '8株全球好菌株+4种益生元，纯净配方0添加',
        '太空充氮锁鲜黑科技，助力益生菌活性持久待机',
        '独立创意小瓶包装，携带更方便，好吃更好玩'
    ],
    bigTxt: "",
    smallTxtList: [],
    // smallTxtList: ['0蔗糖 0脂肪 0香精  0色素  0乳粉  0防腐剂'],
    detailsList: [
        // 'baby_02.jpg',
        'baby_03.jpg',
        'baby_04.jpg',
        'baby_05.jpg',
        'baby_06.jpg',
        'baby_07.jpg',
        'baby_08.jpg',
        'baby_09.jpg',
        'baby_10.jpg',
        'baby_11.jpg',
        'baby_12.jpg',
        'baby_13.jpg',
        'baby_14.jpg',
    ],
},
{
    name: "畅倍享益生菌固体饮料",
    pageTitle: "菌小宝畅倍享益生菌固体饮料",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/xq-good-1.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/1000yi-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/1000yi-right-icon.png",
    subTitle: "畅倍享益生菌固体饮料",
    subTitleColor: "#00C3B2",
    fontColor: "#00C3B2",
    tagList: [
        '每袋添加1000亿CFU活力菌活性益生菌',
        '臻选全球好菌 2大专利菌株 8菌组合 4种益生元',
        '科学配菌效果好 修·抵·舒三步调理',
        '改善肠道菌群 平衡肠道微生态',
        '成分纯净0添加',
    ],
    bigTxt: "",
    smallTxtList: [],
    detailsList: [
        'box_sbx_1.jpg',
        'box_sbx_2.jpg',
        'box_sbx_3.jpg',
        'box_sbx_4.jpg',
        'box_sbx_5.jpg',
        'box_sbx_6.jpg',
        'box_sbx_7.jpg',
        'box_sbx_8.jpg',
        'box_sbx_9.jpg',
        'box_sbx_10.jpg',
    ],
},
{
    name: "益卫贝益生菌固体饮料",
    pageTitle: "菌小宝益卫贝益生菌固体饮料",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/xq-good-2.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/500yi-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/500yi-right-icon.png",
    subTitle: "益卫贝益生菌固体饮料",
    subTitleColor: "#059DE4",
    fontColor: "#059DE4",
    tagList: [
        '每袋添加500亿CFU活性益生菌',
        '臻选全球好菌 3大专利菌株 9菌组合 4种益生元',
        '精准拒“幽” 构筑护“渭”屏障',
        '成分纯净0添加',
    ],
    bigTxt: "",
    smallTxtList: [],
    // smallTxtList: ['0蔗糖 0脂肪 0香精  0色素  0乳粉  0防腐剂'],
    detailsList: [
        'box_ywb_1.jpg',
        'box_ywb_2.jpg',
        'box_ywb_3.jpg',
        'box_ywb_4.jpg',
        'box_ywb_5.jpg',
        'box_ywb_6.jpg',
        'box_ywb_7.jpg',
        'box_ywb_8.jpg',
        'box_ywb_9.jpg',
        'box_ywb_10.jpg',
        'box_ywb_11.jpg',
        'box_ywb_12.jpg',
    ],
},
{
    name: "益体盈益生菌固体饮料",
    pageTitle: "菌小宝益体盈益生菌固体饮料",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/xq-good-3.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/500yishousheng-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/500yishoushen-right-icon.png",
    subTitle: "益体盈益生菌固体饮料",
    subTitleColor: "#E1345E",
    fontColor: "#E1345E",
    tagList: [
        '每袋添加500亿CFU活性益生菌',
        '甄选全球好菌 4大专利菌株 8菌组合 4种益生元',
        '源头-过程-大环境 三度调节 养出苗条S型',
        '成分纯净0添加',
    ],
    bigTxt: "",
    smallTxtList: [],
    detailsList: [
        'box_yty_1.jpg',
        'box_yty_2.jpg',
        'box_yty_3.jpg',
        'box_yty_4.jpg',
        'box_yty_5.jpg',
        'box_yty_6.jpg',
        'box_yty_7.jpg',
        'box_yty_8.jpg',
        'box_yty_9.jpg',
        'box_yty_10.jpg',
        'box_yty_11.jpg',
    ],
},
{
    name: "益护悦益生菌固体饮料",
    pageTitle: "菌小宝益护悦益生菌固体饮料",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/xq-good-4.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/500yijiankang-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/500yijiankang-right-icon.png",
    subTitle: "益护悦益生菌固体饮料",
    subTitleColor: "#E24278",
    fontColor: "#E24278",
    tagList: [
        '每袋添加500亿CFU活性益生菌',
        '臻选全球好菌 6大专利菌株 12株专业菌 4种益生元',
        '直击源头 三重调理 全面养护V秘健康',
        '成分纯净0添加',
    ],
    bigTxt: "",
    smallTxtList: [],
    detailsList: [
        'box_yhy_1.jpg',
        'box_yhy_2.jpg',
        'box_yhy_3.jpg',
        'box_yhy_4.jpg',
        'box_yhy_5.jpg',
        'box_yhy_6.jpg',
        'box_yhy_7.jpg',
        'box_yhy_8.jpg',
        'box_yhy_9.jpg',
        'box_yhy_10.jpg',
        'box_yhy_11.jpg',
        'box_yhy_12.jpg',
    ],
},
{
    name: "益乐未益生菌固体饮料",
    pageTitle: "菌小宝益乐未益生菌固体饮料",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/xq-good-5.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/200yi-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/200yi-right-icon.png",
    subTitle: "益乐未益生菌固体饮料",
    subTitleColor: "#FAC561",
    fontColor: "#FAC561",
    tagList: [
        '每袋添加200亿CFU活性益生菌',
        '甄选全球好菌 3大专利菌株 9大专业菌 4种益生元',
        '补充一句产品方向的话术',
        '成分纯净0添加',
    ],
    bigTxt: "",
    // smallTxtList: ['0蔗糖 0脂肪 0香精  0色素  0乳粉  0防腐剂'],
    smallTxtList: [],
    detailsList: [
        'box_ylw_1.jpg',
        'box_ylw_2.jpg',
        'box_ylw_3.jpg',
        'box_ylw_4.jpg',
        'box_ylw_5.jpg',
        'box_ylw_6.jpg',
        'box_ylw_7.jpg',
        'box_ylw_8.jpg',
        'box_ylw_9.jpg',
        'box_ylw_10.jpg',
    ],
},
{
    name: "益支星益生菌固体饮料",
    pageTitle: "菌小宝益支星益生菌固体饮料",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/xq-good-6.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/100yi-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/100yi-right-icon.png",
    subTitle: "益支星益生菌固体饮料",
    subTitleColor: "#E68744",
    fontColor: "#E68744",
    tagList: [
        '每袋100亿CFU活性益生菌',
        '臻选6株宝宝可食用菌株 2种益生元',
        '妈妈安心选 宝宝放心吃',
        '成分纯净0添加',
    ],
    bigTxt: "",
    smallTxtList: [],
    // smallTxtList: ['0蔗糖 0脂肪 0香精  0色素  0乳粉  0防腐剂'],
    detailsList: [
        'box_yzx_1.jpg',
        'box_yzx_2.jpg',
        'box_yzx_3.jpg',
        'box_yzx_4.jpg',
        'box_yzx_5.jpg',
        'box_yzx_6.jpg',
        'box_yzx_7.jpg',
        'box_yzx_8.jpg',
        'box_yzx_9.jpg',
        'box_yzx_10.jpg',
        'box_yzx_11.jpg',
        'box_yzx_12.jpg',
        'box_yzx_13.jpg',
    ],
}, {
    name: "太空1号畅态舒然益生菌",
    pageTitle: "菌小宝太空1号畅态舒然益生菌",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/xq-good-tk-1-new.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/tk1-1000yi-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/1000yi-right-icon.png",
    subTitle: "太空1号畅态舒然益生菌",
    subTitleColor: "#00C3B2",
    fontColor: "#00C3B2",
    tagList: [
        '每袋添加1000亿CFU活力菌活性益生菌',
        '全球甄选10株优质菌株',
        '10株益生菌+4种益生元，纯净配方0添加',
        '太空充氮锁鲜黑科技，助力益生菌活性持久待机',
        '独立创意小瓶包装，携带更方便，好吃更好玩',
    ],
    bigTxt: "",
    smallTxtList: [],
    detailsList: [
        'sky1_ctsr_01.jpg',
        'sky1_ctsr_02.jpg',
        'sky1_ctsr_03.jpg',
        'sky1_ctsr_04.jpg',
        'sky1_ctsr_05.jpg',
        'sky1_ctsr_06.jpg',
        'sky1_ctsr_07.jpg',
        'sky1_ctsr_08.jpg',
        'sky1_ctsr_09.jpg',
        'sky1_ctsr_10.jpg',
        'sky1_ctsr_11.jpg',
        'sky1_ctsr_12.jpg',
        'sky1_ctsr_13.jpg',
        'sky1_ctsr_14.jpg',
        'sky1_ctsr_15.jpg',
        'sky1_ctsr_16.jpg',
        'sky1_ctsr_17.jpg',
        'sky1_ctsr_18.jpg',
        'sky1_ctsr_19.jpg',
    ],
    type: 'sky',
},
{
    name: "太空2号蔚蓝无幽益生菌",
    pageTitle: "菌小宝太空2号蔚蓝无幽益生菌",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/xq-good-tk-2-new.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/tk2-1000yi-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/500yi-right-icon.png",
    subTitle: "太空2号蔚蓝无幽益生菌",
    subTitleColor: "#059DE4",
    fontColor: "#059DE4",
    tagList: [
        '每瓶添加1000亿CFU活性益生菌',
        '全球甄选12株优质菌株',
        '12株益生菌+5种益生元，纯净配方0添加',
        '特别添加银耳多糖',
        '太空充氮锁鲜黑科技，助力益生菌活性持久待机',
        '独立创意小瓶包装，携带更方便，好吃更好玩',
    ],
    bigTxt: "",
    smallTxtList: [],
    // smallTxtList: ['0蔗糖 0脂肪 0香精  0色素  0乳粉  0防腐剂'],
    detailsList: [
        'sky2_wlwy_01.png',
        'sky2_wlwy_02.png',
        'sky2_wlwy_03.png',
        'sky2_wlwy_04.png',
        'sky2_wlwy_05.png',
        'sky2_wlwy_06.png',
        'sky2_wlwy_07.png',
        'sky2_wlwy_08.png',
        'sky2_wlwy_09.png',
        'sky2_wlwy_10.png',
        'sky2_wlwy_11.png',
        'sky2_wlwy_12.png',
        'sky2_wlwy_13.png',
        'sky2_wlwy_14.png',
        'sky2_wlwy_15.jpg',
    ],
    type: 'sky',
},
{
    name: "太空5号轻盈悠然益生菌",
    pageTitle: "菌小宝太空5号轻盈悠然益生菌",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/xq-good-tk-3-new.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/tk5-1000yi-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/500yishoushen-right-icon.png",
    subTitle: "太空5号轻盈悠然益生菌",
    subTitleColor: "#E1345E",
    fontColor: "#E1345E",
    tagList: [
        '每瓶添加1000亿CFU活性益生菌',
        '全球甄选12株优质菌株',
        '12株益生菌+5种益生元，纯净配方0添加',
        '特别添加白芸豆提取物、马铃薯提取物、金针菇粉',
        '太空充氮锁鲜黑科技，助力益生菌活性持久待机',
        '独立创意小瓶包装，携带更方便，好吃更好玩',
    ],
    bigTxt: "",
    smallTxtList: [],
    detailsList: [
        'sky5_qyyr_01.jpg',
        'sky5_qyyr_02.jpg',
        'sky5_qyyr_03.jpg',
        'sky5_qyyr_04.jpg',
        'sky5_qyyr_05.jpg',
        'sky5_qyyr_06.jpg',
        'sky5_qyyr_07.jpg',
        'sky5_qyyr_08.jpg',
        'sky5_qyyr_09.jpg',
        'sky5_qyyr_10.jpg',
        'sky5_qyyr_11.jpg',
        'sky5_qyyr_12.jpg',
        'sky5_qyyr_13.jpg',
        'sky5_qyyr_14.jpg',
        'sky5_qyyr_15.jpg',
        'sky5_qyyr_16.jpg',
        'sky5_qyyr_17.jpg',
        'sky5_qyyr_18.jpg',
    ],
    type: 'sky',
},
{
    name: "太空6号卫酐金爵益生菌",
    pageTitle: "菌小宝太空6号卫酐金爵益生菌",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/xq-good-tk-4-new.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/tk6-1000yi-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/500yijiankang-right-icon.png",
    subTitle: "太空6号卫酐金爵益生菌",
    subTitleColor: "#E24278",
    fontColor: "#E24278",
    tagList: [
        '每瓶添加1000亿CFU活性益生菌',
        '全球甄选28株优质菌株',
        '28株益生菌+5种益生元，纯净配方0添加',
        '特别添加蔬菜之皇——朝鲜蓟粉',
        '太空充氮锁鲜黑科技，助力益生菌活性持久待机',
        '独立创意小瓶包装，携带更方便，好吃更好玩',
    ],
    bigTxt: "",
    smallTxtList: [],
    detailsList: [
        'sky6_wgjj_01.jpg',
        'sky6_wgjj_02.jpg',
        'sky6_wgjj_03.jpg',
        'sky6_wgjj_04.jpg',
        'sky6_wgjj_05.jpg',
        'sky6_wgjj_06.jpg',
        'sky6_wgjj_07.jpg',
        'sky6_wgjj_08.jpg',
        'sky6_wgjj_09.jpg',
        'sky6_wgjj_10.jpg',
        'sky6_wgjj_11.jpg',
        'sky6_wgjj_12.jpg',
        'sky6_wgjj_13.jpg',
        'sky6_wgjj_14.jpg',
        'sky6_wgjj_15.jpg',
        'sky6_wgjj_16.jpg',
        'sky6_wgjj_17.jpg',
        'sky6_wgjj_18.jpg',
        'sky6_wgjj_19.jpg',
    ],
    type: 'sky',
},
{
    name: "太空10号宝护萌士益生菌",
    pageTitle: "菌小宝太空10号宝护萌士益生菌",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/xq-good-tk-5-new.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/tk10-1000yi-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/200yi-right-icon.png",
    subTitle: "太空10号宝护萌士益生菌",
    subTitleColor: "#FAC561",
    fontColor: "#FAC561",
    tagList: [
        '每瓶添加200亿CFU活性益生菌',
        '全球甄选10株优质菌株',
        '10株益生菌+4种益生元，纯净配方0添加',
        '特别添加4株婴幼儿可食用菌株',
        '太空充氮锁鲜黑科技，助力益生菌活性持久待机',
        '独立创意小瓶包装，携带更方便，好吃更好玩',
    ],
    bigTxt: "",
    // smallTxtList: ['0蔗糖 0脂肪 0香精  0色素  0乳粉  0防腐剂'],
    smallTxtList: [],
    detailsList: [
        'sky10_bhms_01.jpg',
        'sky10_bhms_02.jpg',
        'sky10_bhms_03.jpg',
        'sky10_bhms_04.jpg',
        'sky10_bhms_05.jpg',
        'sky10_bhms_06.jpg',
        'sky10_bhms_07.jpg',
        'sky10_bhms_08.jpg',
        'sky10_bhms_09.jpg',
        'sky10_bhms_10.jpg',
        'sky10_bhms_11.jpg',
        'sky10_bhms_12.jpg',
        'sky10_bhms_13.jpg',
        'sky10_bhms_14.jpg',
        'sky10_bhms_15.jpg',
        'sky10_bhms_16.jpg',
        'sky10_bhms_17.jpg',
        'sky10_bhms_18.jpg',
        'sky10_bhms_19.jpg',
    ],
    type: 'sky',
},
{
    name: "太空17号安稳舒悠益生菌",
    pageTitle: "菌小宝太空17号安稳舒悠益生菌",
    productImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/xq-good-tk-6-new.png",
    logoImgUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/tk17-1000yi-logo.png",
    rightIconUrl: "https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/100yi-right-icon.png",
    subTitle: "太空17号安稳舒悠益生菌",
    subTitleColor: "#E68744",
    fontColor: "#E68744",
    tagList: [
        '每瓶添加1000亿CFU鲜活益生菌',
        '全球甄选8株优质菌株',
        '8株益生菌+4种益生元，纯净配方0添加',
        '特别添加GABA（γ-氨基丁酸）',
        '太空充氮锁鲜黑科技，助力益生菌活性持久待机',
        '独立创意小瓶包装，携带更方便，好吃更好玩',
    ],
    bigTxt: "",
    smallTxtList: [],
    // smallTxtList: ['0蔗糖 0脂肪 0香精  0色素  0乳粉  0防腐剂'],
    detailsList: [
        'sky17_awsy_01.jpg',
        'sky17_awsy_02.jpg',
        'sky17_awsy_03.jpg',
        'sky17_awsy_04.jpg',
        'sky17_awsy_05.jpg',
        'sky17_awsy_06.jpg',
        'sky17_awsy_07.jpg',
        'sky17_awsy_08.jpg',
        'sky17_awsy_09.jpg',
        'sky17_awsy_10.jpg',
        'sky17_awsy_11.jpg',
        'sky17_awsy_12.jpg',
        'sky17_awsy_13.jpg',
        'sky17_awsy_14.jpg',
        'sky17_awsy_15.jpg',
        'sky17_awsy_16.jpg',
    ],
    type: 'sky',
}
];

export function returnProductDetail(name) {
    let currentDetail = null;
    productList.map(item => {
        if (item.name === name) {
            currentDetail = item;
        }
    })
    return currentDetail;
}
