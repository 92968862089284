<template>
  <div class="wrap">
    <!--    header-->
    <gw-header page="/product" ref="gwheader"></gw-header>
    <!-- <img src="https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/hz-demo-1.png" alt=""> -->
    <!-- <img src="https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/hz-demo-2.png" alt=""> -->
    <div
      class="width-100"
      style="
        background: linear-gradient(to bottom, #dddddd, #fcfcfc, #dddddd);
        margin-bottom: 6rem;
        padding: 18rem 0;
      "
    >
      <div class="content-width">
        <el-row type="flex" align="middle" class="block-flex">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"  class="good-img-wrap">
            <img :src="details.productImgUrl" class="good-img-sky" v-if="details.type == 'sky'" />
            <img :src="details.productImgUrl" class="good-img" v-else />
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <div class="main-right">
              <div class="right-logo" v-if="details.logoImgUrl">
                <img :src="details.logoImgUrl" />
              </div>
              <!-- :style="{color: details.subTitleColor}" -->
              <div class="right-subTitle" v-html="details.subTitle"></div>
              <div class="right-tags" v-for="(item, index) in details.tagList">
                <!-- <img :src="details.rightIconUrl" /> -->
                <!-- :style="{color: details.fontColor}" -->
                <span class="big-icon"></span>
                <p>{{ item }}</p>
              </div>
              <div class="right-bigText" v-if="details.bigTxt">
                {{ details.bigTxt }}
              </div>
              <div
                style="height: 6rem"
                v-if="details.smallTxtList.length > 1"
              ></div>
              <div
                class="right-smallTxtList"
                v-for="(item, index) in details.smallTxtList"
              >
                {{ item }}
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="width-100">
      <div
        :class="'product-content-width'"
        v-for="(item, index) in details.detailsList"
        :key="index"
      >
        <!-- <span class="tpxh">图片序号：{{ index + 1 }}</span> -->
        <img :src="util.getImageUrl(item)" />
      </div>
      <!-- <div :class="index === 0 ? 'product-content-banner' : 'product-content-width'" v-for="(item, index) in detailsList" :key="index">
				<img :src="util.getImageUrl(item)" />
			</div> -->
    </div>
    <!--    footer-->
    <gw-footer></gw-footer>
  </div>
</template>

<script>
import gwHeader from "../../components/header.vue";
import gwFooter from "../../components/footer.vue";
import { returnProductDetail } from "./product_json.js";
export default {
  name: "product",
  components: {
    gwHeader,
    gwFooter,
  },
  data() {
    return {
      details: [],
    };
  },
  created() {
    const { name } = this.$route.query;
    let data = returnProductDetail(name);
    if (data) {
      this.details = data;
      document.title = data.pageTitle + "-上海晨冠乳业有限公司";
    }
  },
  methods: {},
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.right-tags {
  display: flex;
  align-items: center;
  position: relative;
  // padding-left: 1.3rem;
}
.big-icon {
  // font-size: 4rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  overflow: hidden;
  background: #333333;
  color: #333333;
  margin-right: 0.8rem;
}
.good-img-wrap{
  display: flex;
  justify-content: flex-end;
}
.good-img {
  max-width: 500px;
  position: relative;
  right: 0;
}
.good-img-sky{
  max-width: 480px;
  position: relative;
  right: 30px;
}
.main-right {
  width: 100%;
  box-sizing: border-box;
  padding-left: 6rem;
  height: auto;
  overflow: hidden;
  .right-logo {
    width: 28rem;
    height: auto;
    display: block;
  }
  .right-subTitle {
    font-size: 3.8rem;
    // font-size: 30px;
    font-weight: bold;
    margin: 2rem 0;
  }
  .right-tags {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    img {
      display: block;
      width: 0.5rem;
      height: auto;
      margin-right: 1rem;
    }
    p {
      font-size: 2.2rem;
      font-weight: 500;
      margin: 0.2rem 0;
    }
  }
  .right-bigText {
    font-size: 4rem;
    font-weight: bold;
    color: #333333;
    margin-top: 3rem;
  }
  .right-smallTxtList {
    font-size: 2.2rem;
    color: #333333;
  }
}
.product-content-banner {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 6rem;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
.product-content-width {
  width: 1200px;
  // width: 400px!important;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
// .tpxh{
// 	font-size: 28px;
// }
// .product-content-width:nth-child(odd){
// 	border: 10px solid blue;
// 	margin-bottom: 10px;
// }

// .product-content-width:nth-child(even){
// 	border: 10px solid red;
// 	margin-bottom: 10px;
// }

//  xs  小屏手机设备
@media only screen and (max-width: 767px) {
  .block-flex {
    display: block;
  }
  .product-content-banner {
    margin-bottom: 0;
  }
  .product-content-width {
    width: 100%;
  }
}

//  sm  手机设备
@media only screen and (min-width: 768px) {
  .block-flex {
    display: block;
  }
  .product-content-banner {
    margin-bottom: 0;
  }
  .product-content-width {
    width: 100%;
  }
}

// md  平板设备
@media only screen and (min-width: 992px) {
  .block-flex {
    display: flex;
  }
  .product-content-banner {
    margin-bottom: 0;
  }
  .product-content-width {
    width: 100%;
  }
}

// lg  一般电脑设备
@media only screen and (min-width: 1200px) {
  .block-flex {
    display: flex;
  }
  .product-content-banner {
    margin-bottom: 6rem;
  }
  .product-content-width {
    width: 1000px;
  }
}

// xl  屏幕较大设备
@media only screen and (min-width: 1920px) {
  .block-flex {
    display: flex;
  }
  .product-content-banner {
    margin-bottom: 6rem;
  }
  .product-content-width {
    width: 1200px;
  }
}
</style>
