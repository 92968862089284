<template>
	<div class="wrap">
		<!--    header-->
		<gw-header page="/newsDetails" ref="gwheader"></gw-header>
		<div class="news-details-width">
			<h3 class="details-h3">{{title}}</h3>
			<p class="details-p">发布时间：{{createTime}}</p>
			<div style="width: 100%;" v-html="details"></div>
			<div class="goBack" @click="goBack()">返回列表</div>
		</div>
		<!--    footer-->
		<gw-footer></gw-footer>
	</div>
</template>

<script>
	import gwHeader from '../../components/header.vue';
	import gwFooter from '../../components/footer.vue';
	import { chgryNewsDetail } from '../../api.js';
	export default {
		name: 'newsDetails',
		components: {
			gwHeader,
			gwFooter
		},
		data() {
			return {
				id: null,
				title: null,
				details: null,
				createTime: null,
			}
		},
		created() {
			const { id } = this.$route.query;
			this.id = id;
			this.getData();
		},
		methods: {
			goBack(){
				this.$router.go(-1);
			},
			// 获取新闻详情
			async getData(){
				const loading = this.$loading({
					lock: true,
					text: '加载中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				const { data } = await chgryNewsDetail(this.id);
				if(data){
					this.title = data.news_title;
					this.details = data.news_detail;
					document.title = data.news_title + '-上海晨冠乳业有限公司';
					this.createTime = this.util.dateFormat(data.create_time);
				}
				loading.close();
				
			},
			
		},
		mounted() {

		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	.news-details-width{
		width: 1200px;
		margin: 2rem auto 5rem;
		height: auto;
		overflow: hidden;
		.details-h3{
			width: 100%;
			font-size: 3rem;
			color: #333333;
		}
		.details-p{
			font-size: 1.6rem;
			color: #999999;
			padding-bottom: 2rem;
			width: 100%;
			border-bottom: 1px solid #E6E6E6;
			margin-top: 1rem;
		}
	}
	.goBack{
		width: 100px;
		height: 40px;
		background-color: #F4F4F4;
		color: #333333;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.6rem;
		line-height: 40px;
		margin: 4rem auto;
		border-radius: 5px;
		cursor: pointer;
	}

	//  xs  小屏手机设备
	@media only screen and (max-width: 767px) {
		.block-flex {
			display: block;
		}
		.news-details-width{
			width: 90%;
		}
	}

	//  sm  手机设备
	@media only screen and (min-width: 768px) {
		.block-flex {
			display: block;
		}
		.news-details-width{
			width: 90%;
		}
	}

	// md  平板设备
	@media only screen and (min-width: 992px) {
		.block-flex {
			display: flex;
		}
		.news-details-width{
			width: 90%;
		}
	}

	// lg  一般电脑设备
	@media only screen and (min-width: 1200px) {
		.block-flex {
			display: flex;
		}
		.news-details-width{
			width: 1000px;
		}
	}

	// xl  屏幕较大设备
	@media only screen and (min-width: 1920px) {
		.block-flex {
			display: flex;
		}
		.news-details-width{
			width: 1200px;
		}
	}
</style>
