<template>
  <div class="wrap">
    <!--    header-->
    <gw-header page="/industry" ref="gwheader"></gw-header>
    <div class="width-100 margin-bottom-4rem">
      <img :src="util.getImageUrl('industry_02.jpg')" />
    </div>
    <div class="content-width margin-bottom-4rem">
      <el-row :gutter="200" type="flex" align="top" class="block-flex">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="width-100 content-text">
            <h3>晨冠乳业</h3>
            <p>
              上海晨冠乳业有限公司成立于2002年2月，坐落在上海市奉贤东方美谷园区，是晨冠集团旗下专注于高端婴幼儿配方奶粉及乳制营养品的研发、生产和经营的企业。晨冠为首批通过婴幼儿配方奶粉国家注册制的企业之一，也是中国乳制品工业协会向消费者推荐的重点奶粉品牌企业。
            </p>
            <p>
              经过20年的经营，晨冠乳业已经发展成为晨冠集团婴配粉等健康营养品生产基地，为集团高品质产品的生产制造提供坚实基础和保障。
            </p>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="width-100 content-text">
            <h3>——</h3>
            <p>
              公司拥有现代化透明工厂，建有GMP全封闭洁净生产车间、包装车间、标准立体仓库，配备国际先进全自动生产线；建有国际一流检测中心，配备先进检验检测设备，对原料、生产、包装到成品实施全过程全面质量检查和监控，确保原料使用合格率、产品出厂合格率、外部抽检合格率三个百分百；建立了完善的ISO9001质量管理体系、ISO14001环境管理体系、HACCP危害分析与关键控制点体系认证及国家诚信管理体系，实现了产品生产从原料到成品全过程均处于严密可控状态，确保产品的高品质和质量安全。
            </p>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="content-width margin-bottom-4rem" style="position: relative">
      <div class="item1-content">
        <p>
          公司建立了优质原料供应链，与国际知名品牌原料供应商结成战略合作，甄选全球优质原料，保证产品原料需求。
        </p>
        <p>
          公司斥资建立的晨冠国际营养研究中心，致力于婴幼儿配方奶粉等营养品的研发。中心汇集了国内外知名营养研究机构、高校科研院所的营养专家及乳品专家，组成专业的研发团队，创新开发满足不同人群需求的高品质产品。中心自成立以来，多项研究成果荣获科技进步奖、产品创新奖。目前，研究范围已延伸至奶类营养品、益生菌品类、美容营养品类及药食同源品类等健康领域。
        </p>
        <p>
          晨冠在立足发展的同时，积极参与社会公益事业，得到了奉贤区党委、政府及社会各界的关怀和肯定，并荣获“区长质量奖”、“上海名牌”、“上海著名商标”、“扶贫明星奖”等多项殊荣。
        </p>
      </div>
      <div class="width-100 caleBox-img">
        <img :src="util.getImageUrl('industry_03.jpg')" />
      </div>
    </div>
    <div class="content-width margin-bottom-4rem">
      <div class="width-100">
        <img :src="util.getImageUrl('industry_05.jpg')" />
      </div>
    </div>
    <div class="content-width margin-bottom-4rem">
      <el-row :gutter="200" type="flex" align="top" class="block-flex">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="width-100 content-text">
            <h2>妈咪全知道</h2>
            <p>
              上海妈咪全知道健康科技（集团）有限公司成立于2019年1月，由上海晨冠健康科技集团投资控股、东方美谷产业基金领投，致力于为健康家庭提供商品和服务的智慧零售平台企业。
            </p>
            <!-- <p>
							<span><countTo :startVal='0' :endVal='2019' :duration='2000'></countTo></span>年
							<span><countTo :startVal='0' :endVal='1' :duration='2000'></countTo></span>月
							<span><countTo :startVal='0' :endVal='11' :duration='2000'></countTo></span>日
						</p> -->
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="width-100 content-text">
            <h3>——</h3>
            <p>
              妈咪全知道聚焦家庭健康需求领域，按照产业化、生态型平台发展模式，始终坚持创新引领、创新经营，不断推出创新创业的“新领域、新项目、新品牌、新模式”，应用互联网科技，构建线上线下全渠道融合的中国健康家庭服务平台。
            </p>
            <p>
              妈咪全知道平台秉承“共同的品牌、共同的事业、共同的未来”合作发展理念，以其独特的运营理念和合作方式，携手合作商、品牌商、渠道商、用户以及从业人员开展经营、项目合作、兴业创业，共同为国人“家庭健康，健康家庭”事业服务，让每一个家庭享受健康新生活。
            </p>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="content-width margin-bottom-4rem" style="padding: 0 8.34vw">
      <!-- <div class="width-100">
        <img :src="util.getImageUrl('junxiaobao-banner.jpg')" />
      </div> -->
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in bannerList"
            :key="index"
          >
            <img style="display: block;" :src="util.getImageUrl(item)" />
          </div>
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
        <div class="swiper-pagination"></div>
      </div>
    </div>

    <div class="content-width margin-bottom-4rem">
      <el-row :gutter="200" type="flex" align="top" class="block-flex">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="width-100 content-text">
            <h2>菌小宝</h2>
            <p>
              菌小宝，诞生于中国上海，是晨冠健康科技集团旗下的专业益生菌品牌。
            </p>
            <p>
              秉承“科学新营养
              纯净0添加”的品牌理念，菌小宝重新定义益生菌新营养，一举攻克千亿益生菌技术研发壁垒，成为中国“千亿活菌领航者”。
            </p>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="width-100 content-text">
            <h3>——</h3>

            <p>
              从婴幼儿益生菌，到成人益生菌，菌小宝始终致力于“为国人提供科学、精准、高效的全人群益生菌新营养解决方案”，从而提升国人的整体生命健康，助力健康中国发展。
            </p>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="content-width margin-bottom-4rem">
      <div class="width-100">
        <img :src="util.getImageUrl('industry_08.jpg')" />
      </div>
    </div>
    <div class="content-width margin-bottom-4rem">
      <el-row :gutter="200" type="flex" align="top" class="block-flex">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="width-100 content-text">
            <h2>冠通新创</h2>
            <p>
              冠通新创科技（上海）有限公司成立于2016年4月，是晨冠集团旗下一家国际化互联网企业,由一批具有国内著名互联网企业服务背景的精英组成,致力于为各类企业客户建设产业互联网化的基础设施平台,并围绕平台打造共享共赢的生态圈,帮助平台上的企业加强信息化建设并提升核心竞争力。
            </p>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="width-100 content-text">
            <h3>——</h3>
            <p>
              目前，冠通自主研发的产品涵盖了食品和医疗器械领域,分别是针对于食品企业的安全信息追溯平台、智能云监管平台、针对于乳制品行业的零售商管理平台,以及针对于医疗器械经营企业的经营管理平台,为企业提供全程管理、实时控制、过程掌控的有效企业运营管理工具。
            </p>
            <p>
              秉承“强而不息勤砥砺,深思造化当有为”的信念,依托强大的技术力量和丰富的业内经验,冠通将以自己的行动和智慧,投身于全新的产业互联网基础设施平台的建设和发展!
            </p>
          </div>
        </el-col>
      </el-row>
    </div>

    <!--    footer-->
    <gw-footer></gw-footer>
  </div>
</template>

<script>
import gwHeader from "../../components/header.vue";
import gwFooter from "../../components/footer.vue";
import countTo from "vue-count-to";
export default {
  name: "industry",
  components: {
    gwHeader,
    gwFooter,
    countTo,
  },
  data() {
    return {
      bannerList: ["home-banner-20230414-1755.jpg", "jtcy-banner-20230417-1618.png"],
    };
  },
  methods: {},
  mounted() {
    new Swiper(".swiper-container", {
      autoplay: 3000,
      speed: 1500,
      loop: true,
      // 如果需要分页器
      pagination: ".swiper-pagination",
      paginationClickable: true,
      // 如果需要前进后退按钮
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev",
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.swiper-button-prev {
  left: 2rem !important;
  background-image: url("https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/icons/left.png");
  background-size: 6rem 6rem;
  // top: calc(50% - 3rem);
  margin-top: -30px;
  width: 6rem;
  height: 6rem;
}
.swiper-button-next {
  right: 2rem !important;
  background-image: url("https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/icons/right.png");
  background-size: 6rem 6rem;
  // top: calc(50% - 3rem);
  margin-top: -30px;
  width: 6rem;
  height: 6rem;
}
.item1-content {
  width: 100%;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  height: 100%;
  box-sizing: border-box;
  padding: 2 10.333vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    box-sizing: border-box;
    padding: 0 10.333vw;
    width: 100%;
    font-size: 1.6rem;
    line-height: 3.5rem;
    color: #ffffff;
    margin: 0;
  }
}
.item1-content:hover + .img-box > img {
  transform: scale(1.1);
}
.right-text {
  h4 {
    text-align: right;
  }
}
.content-text {
  h1 {
    font-size: 4rem;
    color: #333;
    line-height: 6rem;
  }
  h2 {
    font-size: 3.2rem;
    color: #333;
  }

  h3 {
    font-size: 3rem;
    color: #333;
    line-height: 5rem;
  }
  h4 {
    font-size: 2.5rem;
    color: #333;
    line-height: 5rem;
  }

  p {
    font-size: 1.6rem;
    color: #666;
    line-height: 2.5rem;
    span {
      font-size: 4rem;
      color: #333333;
      line-height: 4rem;
    }
  }
}

//  xs  小屏手机设备
@media only screen and (max-width: 767px) {
  .block-flex {
    display: block;
  }
  .right-text {
    h4 {
      text-align: left;
    }
  }
  .caleBox-img {
    height: 60rem;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
}

//  sm  手机设备
@media only screen and (min-width: 768px) {
  .block-flex {
    display: block;
  }
  .right-text {
    h4 {
      text-align: left;
    }
  }
  .caleBox-img {
    height: 60rem;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
}

// md  平板设备
@media only screen and (min-width: 992px) {
  .block-flex {
    display: flex;
  }
  .right-text {
    h4 {
      text-align: right;
    }
  }
  .caleBox-img {
    height: auto;
    img {
      height: auto;
      object-fit: cover;
    }
  }
}

// lg  一般电脑设备
@media only screen and (min-width: 1200px) {
  .block-flex {
    display: flex;
  }
  .right-text {
    h4 {
      text-align: right;
    }
  }
  .caleBox-img {
    height: auto;
    img {
      height: auto;
      object-fit: cover;
    }
  }
}

// xl  屏幕较大设备
@media only screen and (min-width: 1920px) {
  .block-flex {
    display: flex;
  }
  .right-text {
    h4 {
      text-align: right;
    }
  }
  .caleBox-img {
    height: auto;
    img {
      height: auto;
      object-fit: cover;
    }
  }
}
</style>
