<template>
	<div class="wrap">
		<!--    header-->
		<gw-header page="/congerzhuangProduct" ref="gwheader"></gw-header>
		<div class="width-100 margin-bottom-4rem" style="padding: 4rem 0; background: linear-gradient(to right, #FFFFFF 30%, #E9ECF6);  overflow: initial !important;">
			<div class="content-width" style="overflow: initial !important;">
				<el-row :gutter="40" type="flex" justify="center" align="middle" class="block-flex">
					<el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
						<div class="img-box" style="width: 25rem; margin: 0 auto;  overflow: initial !important;">
							<img :src="util.getImageUrl('congerzhuang-bigimg-new1.png')" />
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
						<div class="banner-right">
							<div class="img-box" style="width: 15rem; margin: 2rem 0; overflow: initial !important;">
								<img :src="util.getImageUrl('chg-change-congerzhuang.png')" />
							</div>
                            <p class="margin-bottom-2rem">上海晨冠奶粉，专注宝宝肠健康21年。21年来，晨冠甄选新西兰、法国、美国、荷兰、丹麦、比利时等全球优质原料产地资源，在 “晨冠国际营养研究中心”国内外专家研发团队的精心研究创新下，现已拥有菁粹、安宝素、美可多等多个系列产品，以其“婴幼儿肠道健康专家”的品牌定位和安全高端品质，深受广大客户和消费者信赖和喜爱。</p>
							<!-- <p class="margin-bottom-2rem">2002年，聪尔壮Hikid品牌诞生于上海，是上海晨冠乳业婴幼儿配方奶粉品牌。“聪尔壮”，顾名思义：聪明又强壮。自诞生之日起，就饱含着晨冠乳业和天下父母对宝宝聪明强壮、健康成长的美好祝福！</p>
							<p>上海晨冠“聪尔壮”奶粉，专注宝宝肠健康20年。20年来，“聪尔壮”产品甄选新西兰、法国、美国、荷兰、丹麦、比利时等全球优质原料产地资源，在 “晨冠国际营养研究中心”国内外专家研发团队的精心研究创新下，现已拥有菁粹、安宝素、美可多等多个系列产品，以其“婴幼儿肠道健康专家”的品牌定位和安全高端品质，深受广大客户和消费者信赖和喜爱。</p> -->
							<h3>—</h3>
						</div>
					</el-col>
				</el-row>
			</div>	
		</div>
		<div class="content-width" style="margin: 4rem 0; overflow: initial !important;">
			<el-row :gutter="40" type="flex" justify="center" class="block-flex">
				<el-col :xs="12" :sm="12" :md="8" :lg="4" :xl="4" v-for="(item, index) in productList" :key="index" :style="index === 4 ? 'clear: both' : ''">
					<div class="margin-bottom-4rem manager-content">
						<div class="img-box" style="margin-bottom: 1rem; overflow: initial !important;">
							<img :src="util.getImageUrl(item.imgUrl)" @click="util.jumpPage('/product', {name: item.name});" />
						</div>
						<p @click="util.jumpPage('/product', {name: item.name});">{{item.name}}</p>
					</div>
				</el-col>
				
			</el-row>
		</div>
		<!--    footer-->
		<gw-footer></gw-footer>
	</div>
</template>

<script>
	import gwHeader from '../../components/header.vue';
	import gwFooter from '../../components/footer.vue';
	export default {
		name: 'congerzhuangProduct',
		components: {
			gwHeader,
			gwFooter
		},
		data() {
			return {
				productList: [
					{
						name: "菁粹",
						imgUrl: "congerzhuang-product_03_new1.png",
					},
					{
						name: "安宝素",
						imgUrl: "congerzhuang-product_04_new1.png",
					},
					{
						name: "美可多",
						imgUrl: "congerzhuang-product_05_new.png",
					},
					// {
					// 	name: "儿童粉",
					// 	imgUrl: "congerzhuang-product_06.jpg",
					// },
					// {
					// 	name: "学生粉",
					// 	imgUrl: "congerzhuang-product_07.jpg",
					// },
					
				],
			}
		},
		methods: {
			
		},
		mounted() {

		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	.banner-right{
		width: 80%;
		p{
			font-size: 1.5rem;
			color: #333333;
			margin: 0;
			line-height: 2.5rem;
		}
		h3{
			font-size: 4rem;
			color: #333333;
		}
	}
	.manager-content{
		.img-box{
			height: auto;
			img{
				height: 100%;
			}
		}
		p{
			font-size: 1.5rem;
			color: #333333;
			margin: 0;
			line-height: 2.5rem;
			text-align: center;
			cursor: pointer;
		}
	}

	//  xs  小屏手机设备
	@media only screen and (max-width: 767px) {
		.block-flex {
			display: block;
		}
		.banner-right{
			width: 100%;
		}
	}

	//  sm  手机设备
	@media only screen and (min-width: 768px) {
		.block-flex {
			display: block;
		}
		.banner-right{
			width: 100%;
		}
	}

	// md  平板设备
	@media only screen and (min-width: 992px) {
		.block-flex {
			display: flex;
		}
		.banner-right{
			width: 80%;
		}
	}

	// lg  一般电脑设备
	@media only screen and (min-width: 1200px) {
		.block-flex {
			display: flex;
		}
		.banner-right{
			width: 80%;
		}
	}

	// xl  屏幕较大设备
	@media only screen and (min-width: 1920px) {
		.block-flex {
			display: flex;
		}
		.banner-right{
			width: 80%;
		}
	}
</style>
