<template>
	<div class="wrap">
		<!--    header-->
		<gw-header page="/achievements" ref="gwheader"></gw-header>
		<div class="width-100 margin-bottom-4rem">
			<img :src="util.getImageUrl('achievements_02.jpg')" />
		</div>
		<div class="content-width margin-bottom-4rem" style="margin-top: 4rem;">
			<!-- <img :src="util.getImageUrl('achievements_04.jpg')" />
			<h3>外观设计专利证书-奶粉盖子</h3>
			<p class="margin-bottom-4rem">授权公告日：2014年6月3日</p> -->
			<div id="certify">
				<div class="swiper-container">
					<div class="swiper-wrapper">
						<div class="swiper-slide" v-for="(item, index) in dataList" :key="index">
							<div class="cert-img-box">
								<img :src="util.getImageUrl(item.imgUrl)" />
							</div>
							<h3>{{item.title}}</h3>
							<p>授权公告日：{{item.dateTime}}</p>
						</div>
					</div>
				</div>
				<!-- <div class="swiper-pagination"></div> -->
				<div class="swiper-button-prev"></div>
				<div class="swiper-button-next"></div>
			</div>
		</div>

		<!--    footer-->
		<gw-footer></gw-footer>
	</div>
</template>

<script>
	import gwHeader from '../../components/header.vue';
	import gwFooter from '../../components/footer.vue';
	export default {
		name: 'achievements',
		components: {
			gwHeader,
			gwFooter
		},
		data() {
			return {
				dataList: [{
						imgUrl: 'cert1.jpg?x-oss-process=image/resize,h_850,w_600',
						title: "一种乳粉生产车间的空气净化系统",
						dateTime: "2018年02月23日"
					},
					{
						imgUrl: 'cert2.jpg?x-oss-process=image/resize,h_850,w_600',
						title: "一种乳粉生产工艺中的粉料充填包装机",
						dateTime: "2018年01月05日"
					},
					{
						imgUrl: 'cert3.jpg?x-oss-process=image/resize,h_850,w_600',
						title: "一种槽型混合机",
						dateTime: "2018年01月05日"
					},
					{
						imgUrl: 'cert4.jpg?x-oss-process=image/resize,h_850,w_600',
						title: "一种适用于封罐机的食品罐固定装置",
						dateTime: "2016年08月17日"
					},
					{
						imgUrl: 'cert5.jpg?x-oss-process=image/resize,h_850,w_600',
						title: "一种压缩空气回收装置",
						dateTime: "2018年03月27日"
					},
					{
						imgUrl: 'cert6.jpg?x-oss-process=image/resize,h_850,w_600',
						title: "一种乳粉生产工艺中的臭氧发生器",
						dateTime: "2018年02月23日"
					},
					{
						imgUrl: 'cert7.jpg?x-oss-process=image/resize,h_850,w_600',
						title: "一种乳粉生产车间的空气净化系统",
						dateTime: "2018年02月23日"
					},
				],
			}
		},
		methods: {

		},
		mounted() {
			const _this = this;
			let certifySwiper = new Swiper('#certify .swiper-container', {
				watchSlidesProgress: true,
				slidesPerView: 'auto',
				centeredSlides: true,
				loop: true,
				loopedSlides: 5,
				// autoplay: 3000,
				prevButton: '.swiper-button-prev',
				nextButton: '.swiper-button-next',
				// pagination: '.swiper-pagination',
				//paginationClickable :true,
				onProgress: function(swiper, progress) {
					for (let i = 0; i < swiper.slides.length; i++) {
						var slide = swiper.slides.eq(i);
						var slideProgress = swiper.slides[i].progress;
						var modify = 1;
						if (Math.abs(slideProgress) > 1) {
							modify = (Math.abs(slideProgress) - 1.6) * 0.2 + 1;
						}
						var translate = slideProgress * modify * 260 + 'px';
						var scale = 1 - Math.abs(slideProgress) / 7;
						var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
						slide.transform('translateX(' + translate + ') scale(' + scale + ')');
						slide.css('zIndex', zIndex);
						slide.css('opacity', scale);
						if (Math.abs(slideProgress) > 3) {
							slide.css('opacity', 0);
						}
					}
				},
				onSetTransition: function(swiper, transition) {
					for (var i = 0; i < swiper.slides.length; i++) {
						var slide = swiper.slides.eq(i)
						slide.transition(transition);
					}

				},
				//处理分页器bug
				onSlideChangeStart: function(swiper) {
					// if (swiper.activeIndex == 6) {
					// 	swiper.bullets.eq(9).addClass('swiper-pagination-bullet-active');
					// 	console.log(swiper.bullets.length);
					// }
				}
			});
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	#certify {
		position: relative;
		width: 100%;
		margin: 0 auto
	}
	
	#certify .swiper-container {
		padding-bottom: 60px;
	}
	
	#certify  .swiper-slide {
		width: 520px;
		height: 548px;
		// background: #fff;
		// box-shadow: 0 8px 30px #ddd;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	#certify  .swiper-slide .cert-img-box{
		width: 300px;
		min-height: 420px !important;
		height: 420px !important;
		display:block;
		border: 15px solid transparent;
		border-radius: 15px;
		background-clip: padding-box, border-box;
		background-origin: padding-box, border-box;
		background-image: linear-gradient(to right, #363982, #363982), linear-gradient(90deg, #B0B4F3 0%, #6A72E3 3%, #B0B4F3 95%, #6A72E3 100%);
		margin: 15px 0;
		box-shadow: 0 0 10px #6A72E3;
		display: flex;
		justify-content: center;
		align-items: center;
		img{
			display:block;
			width: 100%;
			height: auto;
			object-fit: cover;
		}
	}
	#certify  .swiper-slide h3{
		text-align: center;
		color: transparent;
		font-size: 2rem;
		font-weight: bold;
		margin-bottom: 1rem;
	}
	#certify  .swiper-slide p {
		padding-top: 0;
		text-align: center;
		color: transparent;
		font-size: 1.6rem;
		margin: 0;
	}
	
	#certify .swiper-pagination {
		width: 100%;
		bottom: 20px;
	}
	.swiper-slide-active{
		p{
			color: #636363 !important;
		}
		h3{
			color: #6C6C6C !important;
		}
	}
	#certify .swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 0 5px;
		border: 3px solid #fff;
		background-color: #d5d5d5;
		width: 10px;
		height: 10px;
		opacity: 1;
	}
	
	#certify .swiper-pagination-bullets .swiper-pagination-bullet-active {
		border: 3px solid #00aadc;
		background-color: #fff;
	}
	
	#certify .swiper-button-prev {
		left: 0;
		width: 3rem;
		height: 6rem;
		background: url("https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/icons/prev.png") no-repeat;
		background-size: 100%;
		margin-top: -80px;
	}
	
	
	#certify .swiper-button-next {
		right: 0;
		width: 3rem;
		height: 6rem;
		background: url("https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/icons/next.png") no-repeat;
		background-size: 100%;
		margin-top: -80px;
	}
	


	//  xs  小屏手机设备
	@media only screen and (max-width: 767px) {
		.block-flex {
			display: block;
		}

		.images-wrap {
			height: 25vw;
		}
		#certify  .swiper-slide .cert-img-box{
			transform: scale(0.7);
		}
	}

	//  sm  手机设备
	@media only screen and (min-width: 768px) {
		.block-flex {
			display: block;
		}

		.images-wrap {
			height: 25vw;
		}
		#certify  .swiper-slide .cert-img-box{
			transform: scale(0.7);
		}
	}

	// md  平板设备
	@media only screen and (min-width: 992px) {
		.block-flex {
			display: flex;
		}

		.images-wrap {
			height: 15vw;
		}
		#certify  .swiper-slide .cert-img-box{
			transform: scale(1);
		}
	}

	// lg  一般电脑设备
	@media only screen and (min-width: 1200px) {
		.block-flex {
			display: flex;
		}

		.images-wrap {
			height: 15vw;
		}
		#certify  .swiper-slide .cert-img-box{
			transform: scale(1);
		}
	}

	// xl  屏幕较大设备
	@media only screen and (min-width: 1920px) {
		.block-flex {
			display: flex;
		}

		.images-wrap {
			height: 15vw;
		}
		#certify  .swiper-slide .cert-img-box{
			transform: scale(1);
		}
	}
</style>
