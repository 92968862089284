<template>
  <div>
    <div
      class="header"
      :style="{ backgroundColor: theme == 'black' ? 'transparent' : '#ffffff' }"
    >
      <!--头部内容-->
      <div class="header-content">
        <div class="header-logo img-box" @click="jumpPage('/')">
          <img :src="util.getImageUrl('logo_03.png')" />
          <!-- <span>晨冠集团</span> -->
        </div>
        <div class="header-nav">
          <ul>
            <li v-for="(item, index) in navList" :key="index">
              <span
                :class="
                  page === item.page || selectedOneLevelNavIndex === index
                    ? 'current-nav'
                    : ''
                "
                @click="jumpPage(item.page)"
                >{{ item.title }}</span
              >
              <ul v-if="item.children">
                <li v-for="(sItem, sIndex) in item.children" :key="sIndex">
                  <span
                    @click="jumpPage(sItem.page)"
                    :class="page === sItem.page ? 'current-nav' : ''"
                    >{{ sItem.title }}</span
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <!--  手机端导航栏  -->
      <div class="mobile-nav">
        <div class="mobile-nav-icon">
          <div class="mobile-nav-logo" @click="jumpPage('/')">
            <img :src="util.getImageUrl('logo_03.png')" />
          </div>
          <img
            id="nav-icon"
            @click="showOrHideMobileNavList()"
            class="mobile-nav-n-icon"
            :src="util.getIconUrl('icon_menu_open@2x.png')"
          />
        </div>
        <ul class="sidenav" :class="showMobileNav ? 'showMobileNav' : ''">
          <li
            v-for="(item, index) in navList"
            :class="showMobileNav ? item.cla + ' mobile-nav-text' : ''"
            :key="index"
          >
            <el-dropdown
              v-if="item.children"
              trigger="click"
              @command="jumpPage"
            >
              <span style="color: #ffffff; font-size: 1.8rem">{{
                item.title
              }}</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(sItem, sIndex) in item.children"
                  :key="sIndex"
                  :command="sItem.page"
                  >{{ sItem.title }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <span v-else @click="jumpPage(item.page)">{{ item.title }}</span>
          </li>
        </ul>
        <div
          class="mobile-nav-left-empty"
          v-if="showMobileNav"
          @click="showOrHideMobileNavList()"
        ></div>
      </div>

      <div class="item-line" v-if="theme != 'black'"></div>
    </div>
    <div class="height-block"></div>
  </div>
</template>

<script>
export default {
  name: "gwHeader",
  props: {
    page: String,
  },
  data() {
    return {
      theme: "white", //主题
      selectedOneLevelNavIndex: null, //在子级页面时选中对应的一级菜单
      navList: [
        //导航列表
        {
          title: "首页",
          page: "/",
        },
        {
          title: "晨冠集团",
          page: "",
          children: [
            {
              title: "公司介绍",
              page: "/companyIntroduction",
            },
            {
              title: "公司管理层",
              page: "/managementLayer",
            },
            {
              title: "集团大事记",
              page: "/greatDeeds",
            },
            {
              title: "集团产业",
              page: "/industry",
            },
            {
              title: "企业宣传片",
              page: "/trailer",
            },
          ],
        },
        {
          title: "品牌与产品",
          page: "",
          children: [
            {
              title: "品牌",
              page: "/brand",
            },
          ],
        },
        {
          title: "研发创新",
          page: "",
          children: [
            {
              title: "晨冠科学研究院",
              page: "/researchInstitute",
            },
            {
              title: "晨冠国际营养研究中心",
              page: "/nutritionCenter",
            },
            // {
            // 	title: "研究成果",
            // 	page: "/achievements"
            // },
          ],
        },
        {
          title: "透明工厂",
          page: "/transparentFactory",
        },
        {
          title: "新闻动态",
          page: "/news",
        },
        {
          title: "加入我们",
          page: "/joinUs",
        },
      ],
      showMobileNav: false, //是否显示手机端导航
    };
  },
  created() {
    this.selectOneNav();
  },
  mounted() {},
  methods: {
    // 在子级页面时选中对应的一级菜单
    selectOneNav() {
      this.navList.map((item, index) => {
        if (item.children) {
          item.children.map((sItem) => {
            if (sItem.page === this.page) {
              this.selectedOneLevelNavIndex = index;
            }
          });
        }
      });
    },
    //显示或隐藏手机端导航菜单
    showOrHideMobileNavList: function () {
      this.showMobileNav = !this.showMobileNav;
    },
    // 跳转页面
    jumpPage(page) {
      if (page) {
        this.util.jumpPage(page);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header {
  width: 100%;
  height: auto;
  // overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  border-bottom: 1px solid #f1f1f1;
  // box-shadow: 0 5px 5px #fcfcfc;

  .header-content {
    height: 4.68vw;
    width: 100%;
    padding: 0 8.333vw;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .header-logo {
      width: 10.93vw;
      height: 100%;
      margin-right: 2.81vw;
      cursor: pointer;
      background-color: #181d61;
      display: flex;
      justify-content: center;
      align-items: center;

      // span{
      // 	font-size: 1.6vw;
      // 	color: #FFFFFF;
      // }
      img {
        display: block;
        width: 85%;
        height: auto;
        margin-top: -0.5rem;
      }
    }

    .header-nav {
      height: 100%;
      display: flex;
      flex-direction: row;
    }

    .header-nav > ul {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
    }

    .header-nav > ul > li {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 2vw;
      height: 100%;
      position: relative;
    }

    .header-nav > ul > li > span {
      font-size: 1.8rem;
      color: $black-color;
      cursor: pointer;
    }

    .header-nav > ul > li:hover > span,
    .header-nav > ul > li > .current-nav,
    .header-nav > ul > li > ul > li > .current-nav {
      color: $red-color;
    }

    .header-nav > ul > li > ul {
      width: calc(100% + 1.8vw);
      height: 0;
      overflow: hidden;
      background-color: #ffffff;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 2;
      padding: 0 1.8vw;
      // border-bottom: 2px solid transparent;
      // display: none;
      opacity: 0;
      transform-origin: center top;
      transition: 0.2s;
    }

    .header-nav > ul > li:hover > ul {
      // display: block;
      height: auto;
      opacity: 1;
      padding: 0.781vw 1.8vw;
      border-bottom: 2px solid $red-color;
    }

    .header-nav > ul > li > ul > li {
      padding: 0.5vw 0;
    }

    .header-nav > ul > li > ul > li > span {
      font-size: 1.6rem;
      color: $black-color;
      cursor: pointer;
    }

    .header-nav > ul > li > ul > li > span:hover {
      color: $red-color;
    }
  }
}

//  xs  小屏手机设备
@media only screen and (max-width: 767px) {
  .header {
    .header-content {
      display: none;
    }

    .mobile-nav {
      display: block;
    }
  }

  .height-block {
    height: 12vw;
  }
}

//  sm  手机设备
@media only screen and (min-width: 768px) {
  .header {
    .header-content {
      display: none;
    }

    .mobile-nav {
      display: block;
    }
  }

  .height-block {
    height: 12vw;
  }
}

// md  平板设备
@media only screen and (min-width: 992px) {
  .header {
    .header-content {
      display: flex;
    }

    .mobile-nav {
      display: none;
    }
  }

  .height-block {
    height: 4.68vw;
  }
  .header-nav span {
    font-size: 1.5rem !important;
  }
}

// lg  一般电脑设备
@media only screen and (min-width: 1200px) {
  .header {
    .header-content {
      display: flex;
    }

    .mobile-nav {
      display: none;
    }
  }

  .height-block {
    height: 4.68vw;
  }

  .header-nav span {
    font-size: 1.6rem !important;
  }
}

// lg  一般电脑设备
@media only screen and (min-width: 1400px) {
  .header-nav span {
    font-size: 1.7rem !important;
  }
}

// xl  屏幕较大设备
@media only screen and (min-width: 1920px) {
  .header {
    .header-content {
      display: flex;
    }

    .mobile-nav {
      display: none;
    }
  }

  .height-block {
    height: 4.68vw;
  }
  .header-nav span {
    font-size: 1.8rem !important;
  }
}

.mobile-nav {
  width: 100%;
  height: auto;
  //overflow: hidden;
  position: relative;
}

.mobile-nav-icon {
  width: 95%;
  // margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // padding: 2rem 0;
  height: 12vw;

  .mobile-nav-logo {
    width: 30vw;
    height: 100%;
    background-color: $theme-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    color: #ffffff;

    // line-height: 5.9rem;
    img {
      display: block;
      width: 85%;
      height: auto;
      margin-top: -0.5rem;
    }
  }

  .mobile-nav-n-icon {
    width: 3rem;
    display: block;
    height: auto;
  }
}

.mobile-nav-left-empty {
  width: calc(100% - 15rem);
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}

.sidenav {
  height: 100vh;
  background: rgb(50, 60, 60);
  width: 15rem;
  box-sizing: border-box;
  margin-bottom: 0;
  position: absolute;
  right: -15rem;
  top: 0;
  bottom: 0;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.showMobileNav {
  right: 0 !important;
}

.sidenav li {
  list-style-type: none;
  color: $white-color;
  font: bold 1.2rem/4.8rem Montserrat;
  opacity: 0;
  transform: translateX(5rem);
  transition: all 0.4s;
  display: block;
  text-align: center;
  height: 6.8rem;
  width: 100%;
  font-size: 1.8rem;
  line-height: 6.8rem;
}

.transX15 {
  transform: translateX(15rem);
}

.mobile-nav-text {
  opacity: 1 !important;
  transform: translateX(0) !important;
}

.mobile-nav-text-item1 {
  transition-delay: 0.08s !important;
}

.mobile-nav-text-item2 {
  transition-delay: 0.16s !important;
}

.mobile-nav-text-item3 {
  transition-delay: 0.24s !important;
}

.mobile-nav-text-item4 {
  transition-delay: 0.32s !important;
}

.mobile-nav-text-item5 {
  transition-delay: 0.4s !important;
}

.mobile-nav-text-item6 {
  transition-delay: 0.48s !important;
}
</style>
