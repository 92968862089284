<template>
	<div class="wrap">
		<!--    header-->
		<gw-header page="/nutritionCenter" ref="gwheader"></gw-header>
		<div class="width-100 margin-bottom-4rem">
			<img :src="util.getImageUrl('nutrition-center_02.jpg')" />
		</div>
		<div class="width-100 flex-row flex-center" style="margin: 4rem 0;">
			<div class="title-left">
				<img :src="util.getImageUrl('nutrition-center_05.jpg')" />
			</div>
			<div class="title-right">
				<h1>上海晨冠国际营养研究中心</h1>
				<h3>CHG International Nutrition Research Center</h3>
			</div>
		</div>
		<div class="content-text content-width margin-bottom-4rem">
			<p class="margin-bottom-4rem">2005年，“晨冠国际营养研究中心”在上海成立。中心汇集了国内外营养研究机构、各大高校科研院所和医院的营养学专家、育儿专家及乳品专家，为我们不断创新、改进产品奠定了坚实的技术基础。使我们能够站在营养科学领域研究的前端，努力打造满足不同人群营养需求的高品质产品。2007年，研究中心与丹麦Danisco合作研发，在国内率先推出含益生菌的婴幼儿配方奶粉，开创了中国人工喂养婴幼儿肠道健康新纪元。</p>
			<el-row :gutter="20">
				<el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6" v-for="(item, index) in peopleList" :key="index">
					<div class="margin-bottom-4rem manager-content">
						<div class="img-box" style="margin-bottom: 1rem; overflow: inherit !important;">
							<img :src="util.getImageUrl(item.imgUrl)" style="width: 80%; margin: 0 auto;" />
						</div>
						<div class="manager-content-text">
							<h3>{{item.name}}</h3>
							<p v-for="(sitem, sindex) in item.tags" :key="sindex">{{sitem}}</p>
						</div>
					</div>
				</el-col>
				
			</el-row>
		</div>
		
		<!--    footer-->
		<gw-footer></gw-footer>
	</div>
</template>

<script>
	import gwHeader from '../../components/header.vue';
	import gwFooter from '../../components/footer.vue';
	export default {
		name: 'nutritionCenter',
		components: {
			gwHeader,
			gwFooter
		},
		data() {
			return {
				peopleList: [
					{
						name: "黄志伟教授",
						imgUrl: "nutrition-center_09.jpg",
						tags: ['医学博士科学家', '美国纽约大学普林斯顿大学中国北京中医药大学教授', '中国中医科学院首席专家美国中医药科学院院长']
					},
					{
						name: "邵玉芬教授",
						imgUrl: "nutrition-center_11.jpg",
						tags: ['复旦大学公共卫生学院教授', '享受国务院政府特殊津贴专家主要召开究', '围产期孕妇及胎儿营养']
					},
					{
						name: "沈月华教授",
						imgUrl: "nutrition-center_13.jpg",
						tags: ['上海交通大学附属国际和平妇幼保健院主任医师', '历任中华围产学会委员', '上海围产学会顾问']
					},
					{
						name: "张艳杰教授",
						imgUrl: "nutrition-center_15.jpg",
						tags: ['硕士生导师高级工程师', '婴配粉专家益生菌专家', '国家婴幼儿配方乳粉科学与营养专业委员会委员', '婴幼儿配方乳粉国家标准起草人']
					},
					{
						name: "高键教授",
						imgUrl: "nutrition-center_21.jpg",
						tags: ['复旦大学附属中山医院营养科主任、', '医学博士、副主任营养师，注册营养师']
					},
					{
						name: "姜培珍教授",
						imgUrl: "nutrition-center_22.jpg",
						tags: ['上海市疾病预防控制中心主任医师', '享受国务院政府政府特殊津贴专家', '国家食品药品监督管理局食品审评专家']
					},
					{
						name: "郭志平教授",
						imgUrl: "nutrition-center_23.jpg",
						tags: ['复旦大学附属儿科医院儿童保健科医师', '享受国务院特殊津贴专家', '主要研究小儿营养及体格生长及其影响因素']
					},
					{
						name: "沈新南教授",
						imgUrl: "nutrition-center_24.jpg",
						tags: ['复旦大学公共卫生学院教授', '硕士生导师', '常年从事人体营养学研究']
					},
					
				],
			}
		},
		methods: {
			
		},
		mounted() {

		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	.title-left{
		width: 10rem;
		margin-right: 1rem;
	}
	.title-right{
		h1{
			font-size: 3.5rem;
			color: #024DA1;
			line-height: 6rem;
			margin: 0;
			text-align: center;
		}
		h3{
			font-size: 2.3rem;
			color: #024DA1;
			line-height: 3.5rem;
			margin: 0;
			text-align: center;
		}
	}
	.content-text>p{
		font-size: 1.6rem;
		color: #666;
		line-height: 3rem;
	}
	.manager-content{
		.manager-content-text{
			width: 100%;
			height: 22.5rem;
		}
		h3{
			font-size: 2.3rem;
			color: #333;
			line-height: 3.5rem;
			margin: 0;
			text-align: center;
			margin: 2.5rem 0 1rem;
		}
		p{
			font-size: 1.6rem;
			color: #666;
			line-height: 3rem;
			text-align: center;
			margin: 0;
		}
	}

	//  xs  小屏手机设备
	@media only screen and (max-width: 767px) {
		.block-flex {
			display: block;
		}
		.images-wrap{
			height: 25vw;
		}
	}

	//  sm  手机设备
	@media only screen and (min-width: 768px) {
		.block-flex {
			display: block;
		}
		.images-wrap{
			height: 25vw;
		}
	}

	// md  平板设备
	@media only screen and (min-width: 992px) {
		.block-flex {
			display: flex;
		}
		.images-wrap{
			height: 15vw;
		}
	}

	// lg  一般电脑设备
	@media only screen and (min-width: 1200px) {
		.block-flex {
			display: flex;
		}
		.images-wrap{
			height: 15vw;
		}
	}

	// xl  屏幕较大设备
	@media only screen and (min-width: 1920px) {
		.block-flex {
			display: flex;
		}
		.images-wrap{
			height: 15vw;
		}
	}
</style>
