<template>
  <div class="video">
		<img v-show="isShowPlayIcon" class="play-icon" :src="util.getIconUrl('video-start-icon.png')" @click="playVideo()" />
		<img v-show="isShowPlayIcon" class="fm-image" :src="imgSrc" @click="playVideo()" />
		<video v-show="!isShowPlayIcon" ref="myVideo" :muted="muted" loop :autoplay="autoPlay" :src="videoSrc" :controls="controls" preload></video>
		<!-- <video ref="myVideo" :muted="muted" loop :autoplay="autoPlay" :src="videoSrc" :controls="controls === true && isShowPlayIcon === false ? true : false" preload></video> -->
  </div>
</template>

<script>
export default {
  name: "gwVideo",
	props: {
		imgSrc: String,
		videoSrc: String,
		autoPlay: {
			type: Boolean,
			default: false
		},
		controls: {
			type: Boolean,
			default: true
		},
		muted: {
			type: Boolean,
			default: false
		},
	},
  data() {
    return {
			isShowPlayIcon: true,
    }
  },
  mounted() {
		// 监听视频播放
		this.$refs.myVideo.addEventListener("play", () => {
			this.isShowPlayIcon = false;
		});
		// 监听视频暂停
		this.$refs.myVideo.addEventListener("pause", () => {
			this.isShowPlayIcon = true;
		});
		if(this.autoPlay){
			this.$refs.myVideo.play();
		}
	},
  methods:{
		// 播放视频
    playVideo(){
			this.$refs.myVideo.play();
		},
  }
}
</script>

<style lang="scss" scoped>
  .video{
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: relative;
		.play-icon{
			display: block;
			width: 20%;
			height: auto;
			position: absolute;
			left: 40%;
			top: 50%;
			margin-top: -10%;
			cursor: pointer;
			z-index: 9;
		}
		.fm-image{
			
			width: 100%;
			height: 100%;
			// object-fit: cover;
			cursor: pointer;
		}
		video{
			width: 100%;
			height: 100%;
			background-color: #000000;
		}
  }
</style>
