<template>
	<div class="wrap">
		<!--    header-->
		<gw-header page="/companyIntroduction" ref="gwheader"></gw-header>
		<div class="width-100 margin-bottom-4rem">
			<img :src="util.getImageUrl('introduce-banner.jpg')" />
		</div>
		<div class="content-width margin-bottom-4rem">
			<el-row :gutter="200" type="flex" align="top" class="block-flex">
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
					<div class="width-100 content-text">
						<h2>集团介绍</h2>
						<p>2002年，带着致力国人营养健康的初心和梦想，在上海创立了晨冠乳业和“聪尔壮”品牌，晨冠正式扬帆起航。</p>
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
					<div class="width-100 content-text">
            <h2>——</h2>
						<p>2019年，上海晨冠健康科技集团有限公司正式成立。20年来，集团围绕大健康大美丽产业，以“晨冠+”为纽带，以“创新产业平台、创业发展平台、创投孵化平台”为载体，通过资源融合、团队融合、品牌融合、技术融合、资本融合、渠道融合、研发融合、服务融合，推进集团健康美丽事业产业化、专业化、智能化、生态化经营和发展，以高品质产品和服务，助力中国大健康大美丽产业大发展，为中国家庭实现美好品质新生活做出贡献。</p>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="content-width margin-bottom-4rem">
			<el-row :gutter="200" type="flex" align="middle" class="block-flex">
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
					<div class="width-100 content-text img-box">
						<img :src="util.getImageUrl('introduce-item1.jpg')" />
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
					<div class="width-100 content-text">
						<h2>坚持以用户为中心的理念</h2>
						<p>晨冠集团始终坚持以用户为中心的理念，根据不同年龄段人群的生理特点和营养需求，始终坚持自主研发与技术创新，建设现代化透明工厂和十万级GMP标准洁净生产车间，配备国际先进生产线和国际一流检测设备，建立完善的质量管理体系和国际领先的质量管理全程可追溯系统，以科学、严谨、细致的工作作风和工作态度，高标准高要求呈现每一款产品，以丰富的产品和卓越的品质为客户和消费者服务。</p>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="content-width margin-bottom-4rem">
			<el-row :gutter="200" type="flex" align="top" class="block-flex">
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
					<div class="width-100 content-text">
						<h2>科技成就生活之美</h2>
						<p>晨冠创立以来，秉承“致力国人健康事业，科技成就生活之美”的发展愿景，一直专注于高端婴幼儿配方奶粉、乳制营养品等健康领域产品的研发、生产和经营。</p>
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
					<div class="width-100 content-text">
            <h2>——</h2>
						<p>晨冠集团与国内外著名营养研究机构、高校科研院所合作先后成立了“晨冠国际营养研究中心”、“晨冠健康科学研究院”等研发机构，应用先进设备和现代科技，长期不懈致力于创新研发，创新产品，现已形成涵盖奶类营养品、益生菌品类、美容营养品类及药食同源品类等健康领域丰富产品，满足家庭全生命周期健康品需求，以科技的力量成就生活之美，实现国人“家庭健康，健康家庭”梦想。</p>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="width-100 margin-bottom-4rem img-box">
			<img :src="util.getImageUrl('introduce-item2.jpg')" />
		</div>
		<div class="content-width margin-bottom-4rem">
			<el-row :gutter="100" type="flex" align="middle" class="block-flex">
				<el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
					<div class="width-100 content-text">
						<h2>企业文化</h2>
						<p>
              <span style="display: inline-block; width: 100px; text-align: right;">企业价值观：</span>
							关爱&nbsp;&nbsp;责任&nbsp;&nbsp;诚信&nbsp;&nbsp;创新
						</p>
						<p>
              <span style="display: inline-block; width: 100px; text-align: right;">企 业 愿 景：</span>
							致力国人健康事业&nbsp;&nbsp;科技成就生活之美
						</p>
						<p>
							<span style="display: inline-block; width: 100px; text-align: right;">企 业 使 命：</span>
              让每一个家庭享受健康新生活
						</p>
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
					<div class="width-100 content-text img-box">
						<img :src="util.getImageUrl('introduce-item4.jpg')" />
					</div>
				</el-col>
			</el-row>
		</div>
		<!--    footer-->
		<gw-footer></gw-footer>
	</div>
</template>

<script>
	import gwHeader from '../../components/header.vue';
	import gwFooter from '../../components/footer.vue';
	export default {
		name: 'companyIntroduction',
		components: {
			gwHeader,
			gwFooter
		},
		data() {
			return {

			}
		},
		methods: {
			
		},
		mounted() {

		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	.content-text {
		h1{
			font-size: 4rem;
			color: #333;
			line-height: 6rem;
		}
		h2 {
			font-size: 3.2rem;
			color: #333;
			margin-top: 2rem;
			margin-bottom: 2rem;
		}

		h3 {
			font-size: 3rem;
			color: #333;
			line-height: 5rem;
			margin-top: 2rem;
			margin-bottom: 1rem;
		}

		p {
			font-size: 1.6rem;
			color: #666;
			line-height: 2.5rem;
			// span{
			// 	font-size: 2.5rem;
			// 	color: #333333;
			// 	line-height: 4rem;
			// }
		}
	}

	//  xs  小屏手机设备
	@media only screen and (max-width: 767px) {
		.block-flex {
			display: block;
		}
	}

	//  sm  手机设备
	@media only screen and (min-width: 768px) {
		.block-flex {
			display: block;
		}
	}

	// md  平板设备
	@media only screen and (min-width: 992px) {
		.block-flex {
			display: flex;
		}
	}

	// lg  一般电脑设备
	@media only screen and (min-width: 1200px) {
		.block-flex {
			display: flex;
		}
	}

	// xl  屏幕较大设备
	@media only screen and (min-width: 1920px) {
		.block-flex {
			display: flex;
		}
	}
</style>
