<template>
	<div class="wrap">
		<!--    header-->
		<gw-header page="/ticuiProduct" ref="gwheader"></gw-header>
		<div class="width-100" style="padding: 4rem 0; background: linear-gradient(to right, #FFFFFF 30%, #E9ECF6);  overflow: initial !important;">
			<div class="content-width" style="overflow: initial !important;">
				<el-row :gutter="40" type="flex" justify="center" align="middle" class="block-flex">
					<el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
						<div class="img-box" style="width: 35rem; margin: 0 auto; overflow: initial !important;">
							<img :src="util.getImageUrl('ticui-bigimg.png')" />
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
						<div class="banner-right">
							<div class="img-box" style="width: 30rem; margin-bottom: 2rem; overflow: initial !important;">
								<img :src="util.getImageUrl('ticui-logo.png')" />
							</div>
							<p class="margin-bottom-2rem">“媞萃TC18<sup>+</sup>”是上海晨冠健康科技集团旗下品牌，创立于中国上海。</p>
							<p class="margin-bottom-2rem">“媞萃TC18<sup>+</sup>”基于先进的皮肤分析系统，以口服玻尿酸为基础提供综合性肌肤解决方案，通过丰富内在和全新时尚理念来“演绎美的传奇”。</p>
							<p class="margin-bottom-2rem">“媞萃TC18<sup>+</sup>”添加有发酵红毛丹汁、盒胶原蛋白肽、嗜热链球菌、透明质酸钠、山竹、血橙、维生素C等营养成分，具有改善松弛、收紧皮肤、营养关节组织等作用！还可降血压、抗氧化、抗衰老，让消费者轻松喝出亮白的水光肌！</p>
							<p>轻松口服，高效吸收，成就女性时尚美，是新一代女性的肌肤养护的智慧选择。</p>
							<h3>—</h3>
						</div>
					</el-col>
				</el-row>
			</div>	
		</div>
    <div class="width-100" style="margin: 6rem 0;">
      <div :class="'product-content-width'" v-for="(item, index) in itemList" :key="index">
      	<img :src="item" />
      </div>
    </div>
		<!--    footer-->
		<gw-footer></gw-footer>
	</div>
</template>

<script>
	import gwHeader from '../../components/header.vue';
	import gwFooter from '../../components/footer.vue';
	export default {
		name: 'junxiaobaoProduct',
		components: {
			gwHeader,
			gwFooter
		},
		data() {
			return {
				productList: [
					{
						name: "1000亿明星款",
						imgUrl: "junxiaobao-product_03.jpg",
					},
					{
						name: "500亿抗幽门款",
						imgUrl: "junxiaobao-product_04.jpg",
					},
					{
						name: "500亿体重管理款",
						imgUrl: "junxiaobao-product_05.jpg",
					},
					{
						name: "500亿女性健康款",
						imgUrl: "junxiaobao-product_06.jpg",
					},
					{
						name: "200亿儿童款",
						imgUrl: "junxiaobao-product_07.jpg",
					},
					{
						name: "100亿宝宝款",
						imgUrl: "junxiaobao-product_08.jpg",
					},
					
				],
        itemList: [
          'https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/ticui-item1.jpg',
          'https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/ticui-item2.jpg',
          'https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/ticui-item3.jpg',
          'https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/ticui-item4.jpg',
        ],
			}
		},
		methods: {
			
		},
		mounted() {

		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .product-content-width{
  	width: 1200px;
  	height: auto;
  	overflow: hidden;
  	margin: 0 auto;
  	img{
  		display: block;
  		width: 100%;
  		height: auto;
  	}
  }
	.banner-right{
		width: 80%;
		p{
			font-size: 1.5rem;
			color: #333333;
			margin: 0;
			line-height: 2.5rem;
		}
		h3{
			font-size: 4rem;
			color: #333333;
		}
	}
	.manager-content{
		p{
			font-size: 1.5rem;
			color: #333333;
			margin: 0;
			line-height: 2.5rem;
			text-align: center;
			cursor: pointer;
		}
	}

	//  xs  小屏手机设备
	@media only screen and (max-width: 767px) {
		.block-flex {
			display: block;
		}
    .product-content-width{
    	width: 100%;
    }
	}

	//  sm  手机设备
	@media only screen and (min-width: 768px) {
		.block-flex {
			display: block;
		}
    .product-content-width{
    	width: 100%;
    }
	}

	// md  平板设备
	@media only screen and (min-width: 992px) {
		.block-flex {
			display: flex;
		}
    .product-content-width{
    	width: 100%;
    }
	}

	// lg  一般电脑设备
	@media only screen and (min-width: 1200px) {
		.block-flex {
			display: flex;
		}
    .product-content-width{
    	width: 1000px;
    }
	}

	// xl  屏幕较大设备
	@media only screen and (min-width: 1920px) {
		.block-flex {
			display: flex;
		}
    .product-content-width{
    	width: 1200px
    }
	}
</style>
