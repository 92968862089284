<template>
  <div class="wrap">
    <!--    header-->
    <gw-header page="/congerzhuangProduct" ref="gwheader"></gw-header>
    <div v-show="navIndex == 0">
      <div class="width-100 margin-bottom-4rem good-bg">
        <div class="content-width" style="overflow: initial !important">
          <el-row
            :gutter="40"
            type="flex"
            justify="center"
            align="middle"
            class="block-flex"
          >
            <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
              <div
                class="img-box"
                style="
                  width: 35rem;
                  margin: 0 auto;
                  overflow: initial !important;
                "
              >
                <img :src="util.getImageUrl('cp-big-1-tk-new.png')" />
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
              <div class="banner-right">
                <div
                  class="img-box"
                  style="
                    width: 24rem;
                    margin: 2rem 0;
                    overflow: initial !important;
                  "
                >
                  <img :src="util.getImageUrl('tk-1000-logo.png')" />
                </div>
                <p class="margin-bottom-2rem">
                  菌小宝是晨冠健康科技集团旗下的专业益生菌品牌，秉承“全生命周期高端益生菌品牌”的品牌定位和“为国人肠道健康服务”的品牌使命，倡导“人菌共生，更加健康”，菌小宝重新定义益生菌新营养，率先攻克千亿益生菌技术研发壁垒，成为中国“千亿活菌领航者”。以匠心精神打造匠心品质，守护国人肠道健康，助力健康中国。
                </p>
                <h3>—</h3>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div
        class="content-width"
        style="margin: 4rem 0; overflow: initial !important"
      >
        <el-row :gutter="40" type="flex" justify="center" class="block-flex">
          <el-col
            :xs="12"
            :sm="12"
            :md="8"
            :lg="4"
            :xl="4"
            v-for="(item, index) in productListNew"
            :key="index"
            :style="index === 4 ? 'clear: both' : ''"
          >
            <div class="margin-bottom-4rem manager-content">
              <div
                class="img-box"
                style="margin-bottom: 1rem; overflow: initial !important"
              >
                <img
                  :src="util.getImageUrl(item.imgUrl)"
                  @click="util.jumpPage('/product', { name: item.name })"
                />
              </div>
              <p @click="util.jumpPage('/product', { name: item.name })">
                {{ item.name }}
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-show="navIndex == 1">
      <div class="width-100 margin-bottom-4rem good-bg">
        <div class="content-width" style="overflow: initial !important">
          <el-row
            :gutter="40"
            type="flex"
            justify="center"
            align="middle"
            class="block-flex"
          >
            <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
              <div
                class="img-box"
                style="
                  width: 35rem;
                  margin: 0 auto;
                  overflow: initial !important;
                "
              >
                <img :src="util.getImageUrl('cp-big-1.png')" />
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
              <div class="banner-right">
                <div
                  class="img-box"
                  style="
                    width: 24rem;
                    margin: 2rem 0;
                    overflow: initial !important;
                  "
                >
                  <img :src="util.getImageUrl('tk-1000-logo.png')" />
                </div>
                <p class="margin-bottom-2rem">
                  菌小宝是晨冠健康科技集团旗下的专业益生菌品牌，秉承“全生命周期高端益生菌品牌”的品牌定位和“为国人肠道健康服务”的品牌使命，倡导“人菌共生，更加健康”，菌小宝重新定义益生菌新营养，率先攻克千亿益生菌技术研发壁垒，成为中国“千亿活菌领航者”。以匠心精神打造匠心品质，守护国人肠道健康，助力健康中国。
                </p>
                <h3>—</h3>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div
        class="content-width"
        style="margin: 4rem 0; overflow: initial !important"
      >
        <el-row :gutter="40" type="flex" justify="center" class="block-flex">
          <el-col
            :xs="12"
            :sm="12"
            :md="8"
            :lg="4"
            :xl="4"
            v-for="(item, index) in productList"
            :key="index"
            :style="index === 4 ? 'clear: both' : ''"
          >
            <div class="margin-bottom-4rem manager-content">
              <div
                class="img-box"
                style="margin-bottom: 1rem; overflow: initial !important"
              >
                <img
                  :src="util.getImageUrl(item.imgUrl)"
                  @click="util.jumpPage('/product', { name: item.name })"
                />
              </div>
              <p @click="util.jumpPage('/product', { name: item.name })">
                {{ item.name }}
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="nav-wrap">
      <ul class="first-category">
        <li @click="onClickNav(0)" :class="navIndex == 0 ? 'current' : ''">
          <span> 太空系列产品 <em></em></span>
        </li>
        <li @click="onClickNav(1)" :class="navIndex == 1 ? 'current' : ''">
          <span> 专业院线产品 <em></em></span>
        </li>
      </ul>
    </div>
    <!--    footer-->
    <gw-footer></gw-footer>
  </div>
</template>

<script>
import gwHeader from "../../components/header.vue";
import gwFooter from "../../components/footer.vue";
export default {
  name: "junxiaobaoProduct",
  components: {
    gwHeader,
    gwFooter,
  },
  data() {
    return {
      productList: [
        // {
        //   name: "太空1号肠道健康款",
        //   imgUrl: "junxiaobao-product_03.jpg",
        // },
        // {
        //   // name: "500亿拒幽护" + '"' + "渭" +'"款',
        //   name: "太空2号抗幽护胃款",
        //   imgUrl: "junxiaobao-product_04.jpg",
        // },
        // {
        //   name: "太空5号体重管理款",
        //   imgUrl: "junxiaobao-product_05.jpg",
        // },
        // {
        //   name: "太空6号解酒护肝款",
        //   imgUrl: "junxiaobao-product_06.jpg",
        // },
        // {
        //   name: "太空10号儿童守护款",
        //   imgUrl: "junxiaobao-product_07.jpg",
        // },
        // {
        //   name: "太空17号情绪管理款",
        //   imgUrl: "junxiaobao-product_08.jpg",
        // },
        {
          name: "畅倍享益生菌固体饮料",
          imgUrl: "hz-good-1.png",
        },
        {
          name: "益卫贝益生菌固体饮料",
          imgUrl: "hz-good-2.png",
        },
        {
          name: "益体盈益生菌固体饮料",
          imgUrl: "hz-good-3.png",
        },
        {
          name: "益护悦益生菌固体饮料",
          imgUrl: "hz-good-4.png",
        },
        {
          name: "益乐未益生菌固体饮料",
          imgUrl: "hz-good-5.png",
        },
        {
          name: "益支星益生菌固体饮料",
          imgUrl: "hz-good-6.png",
        },
      ],
      productListNew: [
        {
          name: "太空1号畅态舒然益生菌",
          imgUrl: "hz-good-tk-1.png",
        },
        {
          name: "太空2号蔚蓝无幽益生菌",
          imgUrl: "hz-good-tk-2.png",
        },
        {
          name: "太空5号轻盈悠然益生菌",
          imgUrl: "hz-good-tk-3.png",
        },
        {
          name: "太空6号卫酐金爵益生菌",
          imgUrl: "hz-good-tk-4.png",
        },
        {
          name: "太空10号宝护萌士益生菌",
          imgUrl: "hz-good-tk-5.png",
        },
        {
          name: "太空17号安稳舒悠益生菌",
          imgUrl: "hz-good-tk-6.png",
        },
      ],
      navIndex: 0,
    };
  },
  methods: {
    onClickNav(index) {
      this.navIndex = index;
    },
  },
  created() {
    const { index } = this.$route.query;
    console.log(index)
  },
  mounted() {
    this.navIndex = 0;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.banner-right {
  width: 80%;
  p {
    font-size: 1.7rem;
    color: #333333;
    margin: 0;
    line-height: 2.5rem;
  }
  h3 {
    font-size: 4rem;
    color: #333333;
  }
}
.manager-content {
  .img-box {
    height: auto;
    img {
      height: 100%;
    }
  }
  p {
    font-size: 1.5rem;
    color: #333333;
    margin: 0;
    line-height: 2.5rem;
    text-align: center;
    cursor: pointer;
  }
}
.margin-bottom-2rem{
  text-align: justify;
}
.good-bg {
  padding: 4rem 0;
  background: linear-gradient(to right, #ffffff 30%, #e9ecf6);
  overflow: initial !important;
}
.first-category {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  text-align: center;
  border-top: 1px solid #dadada;
}

.first-category li {
  padding: 0 24px;
  display: inline-block;
  vertical-align: to;
  height: 7rem;
  line-height: 7rem;
  cursor: pointer;
}

.first-category li span {
  position: relative;
  font-size: 2.2rem;
  color: #333;
  opacity: 0.19;
}

.first-category li span em {
  font-weight: 400;
  font-style: normal;
  line-height: 1.042vw;
  font-size: 1.042vw;
  position: absolute;
  right: -1.042vw;
  top: -0.521vw;
}

.first-category li.current,
.first-category li:hover {
  // border-top: 0.156vw solid #00c4bd;
  border-top: 0.2rem solid #00c4bd;
}

.first-category li.current span,
.first-category li:hover span {
  opacity: 1;
}
//  xs  小屏手机设备
@media only screen and (max-width: 767px) {
  .block-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .banner-right {
    width: 100%;
  }
  // .good-bg{
  //   background: none;
  // }
}

//  sm  手机设备
@media only screen and (min-width: 768px) {
  .block-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .banner-right {
    width: 100%;
  }
  // .good-bg{
  //   background: none;
  // }
}

// md  平板设备
@media only screen and (min-width: 992px) {
  .block-flex {
    display: flex;
  }
  .banner-right {
    width: 80%;
  }
  // .good-bg{
  //   background: none;
  // }
}

// lg  一般电脑设备
@media only screen and (min-width: 1200px) {
  .block-flex {
    display: flex;
  }
  .banner-right {
    width: 80%;
  }
}

// xl  屏幕较大设备
@media only screen and (min-width: 1920px) {
  .block-flex {
    display: flex;
  }
  .banner-right {
    width: 80%;
  }
}
</style>
