/*
 * @Autor: Mark
 * @Description: 公共接口
 * @Date: 2022-03-22 16:05:37
 * @LastEditors: Mark
 * @LastEditTime: 2022-03-28 16:48:36
 */
import request from './request.js'

//新闻列表
export const chgryNews = (page) => {
    return request({
    url: `/chgry/news?page=${page}`,
    method: 'post',
  });
};

//新闻详情
export const chgryNewsDetail = (id) => {
    return request({
    url: `/chgry/newsDetail?id=${id}`,
    method: 'get',
  });
};

//宣传片列表
export const chgryVideos = () => {
    return request({
    url: `/chgry/videos`,
    method: 'post',
  });
};

//宣传片分类或宣传片列表
export const chgryCategory = (method='get', categoryId) => {
    return request({
    url: method === 'post' ? `/chgry/category?categoryId=${categoryId}` : `/chgry/category`,
    method: method,
		// data: data,
  });
};

//企业大事记
export const chgryNotes = (method='get', year) => {
    return request({
    url: method === 'post' ? `/chgry/notes?year=${year}` : `/chgry/notes`,
    method: method,
		// data: data,
  });
};


