<template>
	<div class="wrap">
		<!--    header-->
		<gw-header page="/transparentFactory" ref="gwheader"></gw-header>
		<div class="width-100 margin-bottom-4rem">
			<gwVideo videoSrc="http://wxapp.gateon.cn/xcpsp.mp4" :muted="true" :autoPlay="true" :controls="false" :imgSrc="util.getImageUrl('factory_02.jpg')"></gwVideo>
		</div>
		<div class="content-width margin-bottom-4rem">
			<el-row :gutter="100" type="flex" align="middle" class="block-flex">
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
					<div class="width-100 content-text">
						<h2 class="margin-bottom-2rem">透明工厂</h2>
						<p>晨冠透明工厂——制造中心坐落在上海奉贤东方美谷园区，拥有现代化全自动生产线、GMP全封闭洁净生产车间、包装车间、标准立体仓库和国际一流检测中心。</p>
						<p>晨冠透明工厂通过了ISO9001质量管理体系、GMP良好生产规范、HACCP危害分析与关键控制点体系、ISO14001环境管理体系、安全标准化认证及国家诚信管理体系评价认证。完善且严格的质量管理体系确保每一款产品的生产全程可控，品质安全。</p>
						<h3>——</h3>
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
					<div class="width-100 content-text img-box" style="width: 80%;overflow: inherit !important; margin: 4rem auto;">
						<img :src="util.getImageUrl('factory_05.jpg')" />
					</div>
				</el-col>
			</el-row>
		</div>
		
		<div class="width-100">
			<div class="margin-bottom-4rem">
				<img :src="util.getImageUrl('factory_08.jpg')" />
			</div>
			<div class="margin-bottom-4rem">
				<img :src="util.getImageUrl('factory_10.jpg')" />
			</div>
			<div class="margin-bottom-4rem">
				<img :src="util.getImageUrl('factory_12.jpg')" />
			</div>
			<div class="margin-bottom-4rem">
				<img :src="util.getImageUrl('factory_14_new1.jpg')" />
			</div>
			
		</div>
		<!--    footer-->
		<gw-footer></gw-footer>
	</div>
</template>

<script>
	import gwHeader from '../../components/header.vue';
	import gwFooter from '../../components/footer.vue';
	import gwVideo from '../../components/video.vue';
	export default {
		name: 'transparentFactory',
		components: {
			gwHeader,
			gwVideo,
			gwFooter
		},
		data() {
			return {

			}
		},
		methods: {
			
		},
		mounted() {

		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	.content-text {
		h1{
			font-size: 4rem;
			color: #333;
			line-height: 6rem;
		}
		h2 {
			font-size: 3.2rem;
			color: #333;
		}

		h3 {
			font-size: 3rem;
			color: #333;
			line-height: 5rem;
		}

		p {
			font-size: 1.6rem;
			color: #666;
			line-height: 2.5rem;
			span{
				font-size: 2.5rem;
				color: #333333;
				line-height: 4rem;
			}
		}
	}

	//  xs  小屏手机设备
	@media only screen and (max-width: 767px) {
		.block-flex {
			display: block;
		}
	}

	//  sm  手机设备
	@media only screen and (min-width: 768px) {
		.block-flex {
			display: block;
		}
	}

	// md  平板设备
	@media only screen and (min-width: 992px) {
		.block-flex {
			display: flex;
		}
	}

	// lg  一般电脑设备
	@media only screen and (min-width: 1200px) {
		.block-flex {
			display: flex;
		}
	}

	// xl  屏幕较大设备
	@media only screen and (min-width: 1920px) {
		.block-flex {
			display: flex;
		}
	}
</style>
