<template>
  <div class="wrap">
    <!--    header-->
    <gw-header page="/" ref="gwheader"></gw-header>
    <div class="width-100 margin-bottom-4rem">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in bannerList"
            :key="index"
          >
            <!-- <div v-if="item == 'home-banner8.mp4'">
							<video src=""></video>
						</div> -->
            <img :src="util.getImageUrl(item)" />
          </div>
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <el-row class="block-flex" type="flex" align="middle">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <div class="width-100 left-font margin-bottom-2rem">
          <h1 class="margin-bottom-2rem">晨冠集团</h1>
          <p class="margin-bottom-2rem">
            2002年，带着致力国人营养健康的初心和梦想，在上海创立了上海晨冠乳业有限公司和“聪尔壮”品牌，晨冠正式扬帆起航。
          </p>
          <!-- <div class="title-font width-100 margin-bottom-2rem">
						<div class="fl">
							<p>成立于</p>
							<div>
								<span><countTo class="bigFont" :startVal='0' :endVal='2002' :duration='2000'></countTo></span>
								<span>年</span>
							</div>
						</div>
						<div class="fl">
							<p>迄今</p>
							<div>
								<span><countTo class="bigFont" :startVal='0' :endVal='20' :duration='2000'></countTo></span>
								<span>年</span>
							</div>
						</div>
					</div> -->
          <p>
            2019年上海晨冠健康科技集团有限公司成立。21年来，集团围绕大健康大美丽产业，以“晨冠+”为纽带，以“创新产业平台、创业发展平台、创投孵化平台”为载体，通过资源融合、团队融合、品牌融合、技术融合、资本融合、渠道融合、研发融合、服务融合，推进集团健康美丽事业产业化，专业化，智能化，生态化经营和发展，以高品质产品和服务，助力中国大健康大美丽产业大发展，为中国家庭实现美好品质新生活做出贡献。
          </p>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <div class="width-100 margin-bottom-4rem img-box">
          <img :src="util.getImageUrl('home-item1-new.jpg')" />
        </div>
      </el-col>
    </el-row>
    <div
      class="width-100 margin-bottom-4rem text-image-content"
      style="position: relative"
    >
      <div class="img-box">
        <img :src="util.getImageUrl('home-item2.jpg')" />
      </div>
      <div class="item1-content">
        <h1 class="margin-bottom-2rem">研发智库</h1>
        <p>研发团队和尖端营养健康科技是晨冠事业的基石！</p>
        <p>
          晨冠集团与国内外著名营养研究机构、高校科研院所合作，先后成立了“晨冠国际营养研究中心”、“晨冠健康科学研究院”，结合国人营养与生命健康需求特点，甄选国际优质原料，创新研发天然营养健康美丽产品，满足不同人群需求，助力旗下品牌出鞘，引爆市场和口碑！
        </p>
      </div>
    </div>
    <!-- <div class="content-width margin-bottom-4rem">
			<el-row :gutter="200" class="margin-bottom-2rem">
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
					<div class="width-100 factory-content">
						<h3>透明工厂</h3>
						<p>晨冠透明工厂——制造中心坐落在上海奉贤东方美谷园区，拥有现代化全自动生产线、GMP全封闭洁净生产车间、包装车间、标准立体仓库和国际一流检测中心。</p>
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
					<div class="width-100 factory-content">
						<h3>——</h3>
						<p>晨冠透明工厂通过了ISO9001质量管理体系、GMP良好生产规范、HACCP危害分析与关键控制点体系、ISO14001环境管理体系、安全标准化认证及国家诚信管理体系评价。完善且严格的质量管理体系，确保每一款产品的生产全程可控，品质安全。</p>
					</div>
				</el-col>
			</el-row>
			<div class="img-box">
				<img :src="util.getImageUrl('home-item3.jpg')" />
			</div>
		</div> -->
    <div class="content-width margin-bottom-4rem flex-row big-title">
      <h3>新闻动态</h3>
      <span @click="util.jumpPage('/news')">更多动态 ></span>
    </div>
    <!--    footer-->
    <gw-footer></gw-footer>
  </div>
</template>

<script>
import gwHeader from "../../components/header.vue";
import gwFooter from "../../components/footer.vue";
import countTo from "vue-count-to";
import { chgryNews } from "../../api.js";
export default {
  name: "Home",
  components: { gwHeader, gwFooter, countTo },
  data() {
    return {
      dataList: [],
      bannerList: [
        "home-banner1-new.jpg",
        "home-banner-20230414-1859.jpg",
        "home-banner2.jpg",
        "home-banner-20230417-1619.png",
        "home-banner4-new1.jpg",
        "home-banner5.jpg",
      ],
    };
  },
  created() {
    // this.getDataList();
  },
  methods: {
    // 获取新闻动态列表
    async getDataList() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data } = await chgryNews(1);
      if (data.length != 0) {
        this.dataList = data || [];
      } else {
        this.dataList = [];
      }
      loading.close();
    },
  },
  mounted() {
    new Swiper(".swiper-container", {
      autoplay: 3000,
      speed: 1500,
      loop: true,
      // 如果需要分页器
      pagination: ".swiper-pagination",
      paginationClickable: true,
      // 如果需要前进后退按钮
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev",
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.swiper-container {
  height: auto;
  overflow: hidden;
  width: 100%;
  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
  }
}

.swiper-button-prev {
  left: 2rem !important;
  background-image: url("https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/icons/left.png");
  background-size: 6rem 6rem;
  // top: calc(50% - 3rem);
  margin-top: -30px;
  width: 6rem;
  height: 6rem;
}
.swiper-button-next {
  right: 2rem !important;
  background-image: url("https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/icons/right.png");
  background-size: 6rem 6rem;
  // top: calc(50% - 3rem);
  margin-top: -30px;
  width: 6rem;
  height: 6rem;
}

.item1-content {
  width: 35%;
  position: absolute;
  z-index: 2;
  left: 8.333vw;
  top: 50%;
  height: 240px;
  margin-top: -120px;
  box-sizing: border-box;
  h1 {
    font-size: 3.2rem;
    color: #ffffff;
  }
  p {
    font-size: 1.6rem;
    color: #ffffff;
  }
  div {
    width: 5rem;
    height: 2px;
    background-color: #ffffff;
    margin: 2rem 0;
  }
}
.left-font {
  box-sizing: border-box;
  padding: 0 8.333vw;

  h1 {
    font-size: 3.2rem;
    color: #333;
  }
  p {
    font-size: 1.6rem;
    color: #666;
  }
  .title-font > div:first-of-type {
    border-right: 1px solid #333333;
    box-sizing: border-box;
  }
  .title-font > div:last-of-type {
    box-sizing: border-box;
    padding-left: 10%;
    box-sizing: border-box;
  }
  .title-font > div {
    width: 50%;
  }
  .title-font {
    div {
      div {
        span:first-of-type {
          .bigFont {
            font-size: 8rem;
            color: #666666;
            line-height: 6.5rem;
          }
        }
        span:last-of-type {
          font-size: 1.6rem;
          // line-height: 1;
          color: #666;
        }
      }
    }
  }
}
.factory-content {
  h3 {
    font-size: 3rem;
    color: #333333;
  }
  p {
    font-size: 1.6rem;
    font-size: #666666;
  }
}
.big-title {
  align-items: center;
  justify-content: space-between;
  h3 {
    font-size: 2.6rem;
    color: #333333;
    margin: 0;
  }
  span {
    font-size: 1.6rem;
    color: #888888;
    padding: 10px 20px;
    border: 2px solid #888888;
    cursor: pointer;
  }
}
.main-content {
  background-color: #f6f7f9;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 4rem;
  .news-images {
    width: 100%;
    height: 30rem;
    overflow: hidden;
    img {
      display: block;
      object-fit: cover;
      transition: 0.5s;
      cursor: pointer;
    }
  }
  .news-images:hover > img {
    transform: scale(1.1);
  }
  h3 {
    width: 90%;
    margin: 2rem auto 0;
    font-size: 2.3rem;
    color: #333;
    line-height: 3.5rem;
    height: 7rem;
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    display: -webkit-box;
    -webkit-line-clamp: 2; // 超出多少行
    -webkit-box-orient: vertical;
    cursor: pointer;
  }
  p {
    width: 90%;
    margin: 2rem auto 4rem;
    font-size: 1.6rem;
    color: #666;
    line-height: 2.5rem;
    height: 10rem;
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    display: -webkit-box;
    -webkit-line-clamp: 4; // 超出多少行
    -webkit-box-orient: vertical;
    cursor: pointer;
  }
}

//  xs  小屏手机设备
@media only screen and (max-width: 767px) {
  .block-flex {
    display: block;
  }
  .text-image-content {
    .img-box {
      height: 40rem;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    .item1-content {
      width: calc(100% - 16.666vw);
    }
  }
  .swiper-button-prev {
    width: 4rem;
    height: 4rem;
    background-size: 4rem 4rem;
    margin-top: -15px;
  }
  .swiper-button-next {
    width: 4rem;
    height: 4rem;
    background-size: 4rem 4rem;
    margin-top: -15px;
  }
}
//  sm  手机设备
@media only screen and (min-width: 768px) {
  .block-flex {
    display: block;
  }
  .text-image-content {
    .img-box {
      height: 40rem;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    .item1-content {
      width: calc(100% - 16.666vw);
    }
  }
  .swiper-button-prev {
    width: 4rem;
    height: 4rem;
    background-size: 4rem 4rem;
    margin-top: -15px;
  }
  .swiper-button-next {
    width: 4rem;
    height: 4rem;
    background-size: 4rem 4rem;
    margin-top: -15px;
  }
}
// md  平板设备
@media only screen and (min-width: 992px) {
  .block-flex {
    display: flex;
  }
  .text-image-content {
    .img-box {
      height: auto;
      img {
        height: auto;
        object-fit: cover;
      }
    }
    .item1-content {
      width: 35%;
    }
  }
  .swiper-button-prev {
    width: 6rem;
    height: 6rem;
    background-size: 6rem 6rem;
    margin-top: -30px;
  }
  .swiper-button-next {
    width: 6rem;
    height: 6rem;
    background-size: 6rem 6rem;
    margin-top: -30px;
  }
}
// lg  一般电脑设备
@media only screen and (min-width: 1200px) {
  .block-flex {
    display: flex;
  }
  .text-image-content {
    .img-box {
      height: auto;
      img {
        height: auto;
        object-fit: cover;
      }
    }
    .item1-content {
      width: 35%;
    }
  }
  .swiper-button-prev {
    width: 6rem;
    height: 6rem;
    background-size: 6rem 6rem;
    margin-top: -30px;
  }
  .swiper-button-next {
    width: 6rem;
    height: 6rem;
    background-size: 6rem 6rem;
    margin-top: -30px;
  }
}
// xl  屏幕较大设备
@media only screen and (min-width: 1920px) {
  .block-flex {
    display: flex;
  }
  .text-image-content {
    .img-box {
      height: auto;
      img {
        height: auto;
        object-fit: cover;
      }
    }
    .item1-content {
      width: 35%;
    }
  }
  .swiper-button-prev {
    width: 6rem;
    height: 6rem;
    background-size: 6rem 6rem;
    margin-top: -30px;
  }
  .swiper-button-next {
    width: 6rem;
    height: 6rem;
    background-size: 6rem 6rem;
    margin-top: -30px;
  }
}
</style>
