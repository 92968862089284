<template>
  <div class="footer">
    <div class="footer-top">
			<div class="fl left-box">
				<dl v-for="(item, index) in navList" :key="index" class="fl">
					<dt>{{item.title}}</dt>
					<dd v-for="(sItem, sIndex) in item.children" :key="sIndex" @click="jumpPage(sItem.page, sItem.type)">{{sItem.title}}</dd>
				</dl>
			</div>
			<div class="fl right-box">
				<p style="font-size: 1.8rem; font-weight: bold; margin-top: 0;">上海晨冠健康科技集团有限公司</p>
				<p>Shanghai Chenguan Health Technology Group Co. , Ltd.</p>
				<p>公司地址：上海市奉贤区望园路2166号</p>
				<p>公司电话： 021-37511111</p>
				<p>电子邮箱：chgry@chgry.com</p>
			</div>
		</div>
		<div class="footer-bottom">
			<div class="fl bottom-left">
				<ul>
					<li>联系我们</li>
					<li>法律声明</li>
					<li>网站地图</li>
				</ul>
				<p><a href="https://beian.miit.gov.cn" target="_blank">沪ICP备10042671号-2</a></p>
			</div>
			<div class="fl bottom-right img-box">
				<!-- <span>晨冠集团</span> -->
				<img :src="util.getImageUrl('logo_03.png')" />
			</div>
		</div>
  </div>
</template>

<script>
export default {
  name: "gwFooter",
  data() {
    return {
      navList: [ //导航列表
      	{
      		title: "晨冠集团",
      		page: "",
      		children: [
      			{ title: "公司介绍", page: "/companyIntroduction", type: "inside"},
      			{ title: "公司管理层", page: "/managementLayer", type: "inside"},
      			{ title: "集团大事记", page: "/greatDeeds", type: "inside"},
      			{ title: "集团产业", page: "/industry", type: "inside"},
      			{ title: "企业宣传片", page: "/trailer", type: "inside"},
      		],
      	},
      	{
      		title: "品牌与产品",
      		page: "",
      		children: [
      			{ title: "晨冠乳业", page: "/", type: "inside"},
						{ title: "晨冠奶粉", page: "/congerzhuangProduct", type: "inside"},
						{ title: "妈咪全知道", page: "http://www.momwow.com.cn/index.html", type: "outside"},
						{ title: "菌小宝", page: "/junxiaobaoProduct", type: "inside"},
						{ title: "冠通新创", page: "http://www.gateon.cn/#/", type: "outside"},
      		],
      	},
      	{
      		title: "研发创新",
      		page: "",
      		children: [
      			{ title: "晨冠健康科学研究院", page: "/researchInstitute", type: "inside"},
      			{ title: "晨冠国际营养研究中心", page: "/nutritionCenter", type: "inside"},
      			// { title: "科研创新成果", page: "/achievements", type: "inside"},
      		],
      	},
      ],
    }
  },
  mounted() {
    
  },
  methods:{
    // 跳转页面
    jumpPage(page, type) {
    	if (type === 'inside') {
    		this.util.jumpPage(page);
    	}else if(type === 'outside'){
				this.util.jumpExternalPage(page);
			}
    },
  }
}
</script>

<style lang="scss" scoped>
  .footer{
		width: 100%;
		height: auto;
		overflow: hidden;
		background-color: #F4F4F4;
		.footer-top{
			box-sizing: border-box;
			width: 100%;
			height: auto;
			overflow: hidden;
			padding: 3vw 8.333vw 4vw;
			.left-box{
				width: 63%;
				height: 100%;
				dl{
					text-align: left;
					font-size: 1.6rem;
					width: 33%;
					margin-bottom: 0;
					dt{
						font-size: 2.2rem;
						font-weight: 400;
						padding-bottom: 2.5rem;
						color: #333;
					}
					dd{
						font-size: 1.6rem;
						color: #666;
						cursor: pointer;
						margin-bottom: 1.6rem;
					}
					dd:hover{
						color: $red-color;
					}
				}
			}
			.right-box{
				width: 36%;
				border-left: 1px solid #e5e5e5;
				text-align: left;
				height: 100%;
				padding-left: 3rem;
				box-sizing: border-box;
				img{
					width: 25vw;
					height: auto;
					display: block;
					margin-bottom: 1rem;
				}
				p{
					font-size: 1.6rem;
					color: #333333;
					// font-weight: bold;
					// text-indent: 1em;
				}
			}
		}
		.footer-bottom{
			width: 100%;
			text-align: left;
			color: #adadad;
			line-height: 4.688vw;
			height: 4.688vw;
			.bottom-left{
				font-size: 1.042vw;
				width: 63%;
				height: 100%;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				padding-left: 8.333vw;
				// border-right: 1px solid #e5e5e5;
				// border-top: 1px solid #e5e5e5;
				box-sizing: border-box;
				ul{
					margin-bottom: 0;
					display: flex;
					align-items: center;
					li{
						// height: 1vw;
						// line-height: 1vw;
						float: left;
						color: #676767;
						padding: 0 1.5vw;
						border-right: 2px solid #676767;
						cursor: pointer;
						font-size: 1.6rem;
						line-height: normal;
					}
					li:last-of-type{
						border-right: 2px solid transparent;
					}
					li:hover{
						color: $red-color;
					}
				}
				p{
					font-size: 1.6rem;
					margin: 0;
					line-height: normal;
				}
				a{
					font-size: 1.6rem;
					line-height: normal;
				}
			}
			.bottom-right{
				box-sizing: border-box;
				width: 37%;
				height: 100%;
				background-color: $theme-color;
				display: flex;
				justify-content: center;
				align-items: center;
				// span{
				// 	font-size: 1.8vw;
				// 	color: #FFFFFF;
				// }
				img{
					display: block;
					width: 30%;
					height: auto;
				}
			}
		}
  }
	//  xs  小屏手机设备
	@media only screen and (max-width: 767px) {
		.footer {
			.footer-top{
				display: none;
			}
			.footer-bottom{
				display: block;
				line-height: 12.688vw;
				height: 12.688vw;
				.bottom-left{
					display: flex;
					flex-direction: column;
					justify-content: center;
					p{
						font-size: 1.042vw;
						margin: 0;
						line-height: 7vw;
					}
				}
				.bottom-right{
					img{
						width: 60%;
					}
				}
			}
		}
	}
	//  sm  手机设备
	@media only screen and (min-width: 768px) {
		.footer {
			.footer-top{
				display: none;
			}
			.footer-bottom{
				display: block;
				line-height: 12.688vw;
				height: 12.688vw;
				.bottom-left{
					display: flex;
					flex-direction: column;
					justify-content: center;
					p{
						font-size: 1.042vw;
						margin: 0;
						line-height: 7vw;
					}
				}
				.bottom-right{
					img{
						width: 60%;
					}
				}
			}
		}
	}
	// md  平板设备
	@media only screen and (min-width: 992px) {
		.footer {
			.footer-top{
				display: block;
			}
			.footer-bottom{
				display: block;
				line-height: 4.688vw;
				height: 4.688vw;
				.bottom-left{
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					p{
						font-size: 1.6rem;
						margin: 0;
					}
				}
				.bottom-right{
					img{
						width: 30%;
					}
				}
			}
		}
		
	}
	// lg  一般电脑设备
	@media only screen and (min-width: 1200px) {
		.footer {
			.footer-top{
				display: block;
			}
			.footer-bottom{
				display: block;
				line-height: 4.688vw;
				height: 4.688vw;
				.bottom-left{
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					p{
						font-size: 1.6rem;
						margin: 0;
					}
				}
				.bottom-right{
					img{
						width: 30%;
					}
				}
			}
		}
		
	}
	// xl  屏幕较大设备
	@media only screen and (min-width: 1920px) {
		.footer {
			.footer-top{
				display: block;
			}
			.footer-bottom{
				display: block;
				line-height: 4.688vw;
				height: 4.688vw;
				.bottom-left{
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					p{
						font-size: 1.6rem;
						margin: 0;
					}
				}
				.bottom-right{
					img{
						width: 30%;
					}
				}
			}
		}
		
	}

</style>
