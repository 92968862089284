import vueMain from './main'
const install = (Vue, vm) => {
    Vue.prototype.util = {
				//获取云icon图片
        getIconUrl(value) {  
            return `https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/icons/${value}`
        },
				//获取云图片
        getImageUrl(value) {  
            return `https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/images/${value}`
        },
				//获取云视频
				getVideoUrl(value) {  
				    return `https://gateon-wxapp.oss-cn-shanghai.aliyuncs.com/chgryWeb/videos/${value}`
				},
				//跳转页面--内部页面
        jumpPage(page, query){  
            vueMain.$router.push({
                path: page,
                query: query
            });
        },
				//跳转页面--打开新的外部页面
        jumpExternalPage(page){  
            window.open (page);
        },
				/**
				 * 时间日期格式化
				 * @param format
				 * @returns {*}
				 */
				dateFormat(dateTime) {
          return dateTime.slice(0, 10);
				  // const date = {
				  //   'M+': dateObj.getMonth() + 1,
				  //   'd+': dateObj.getDate(),
				  //   'h+': dateObj.getHours(),
				  //   'm+': dateObj.getMinutes(),
				  //   's+': dateObj.getSeconds(),
				  //   'q+': Math.floor((dateObj.getMonth() + 3) / 3),
				  //   'S+': dateObj.getMilliseconds()
				  // };
      //     console.log(date);
				  // if (/(y+)/i.test(format)) {
				  //   format = format.replace(
				  //     RegExp.$1,
				  //     (dateObj.getFullYear() + '').substr(4 - RegExp.$1.length)
				  //   );
				  // }
				  // for (const k in date) {
				  //   if (new RegExp('(' + k + ')').test(format)) {
				  //     format = format.replace(
				  //       RegExp.$1,
				  //       RegExp.$1.length === 1
				  //         ? date[k]
				  //         : ('00' + date[k]).substr(('' + date[k]).length)
				  //     );
				  //   }
				  // }
				  // return format;
				},
    }
}
export default {
    install
}

