import Vue from "vue"
import Router from "vue-router";
Vue.use(Router);
import home from "./views/home/index.vue";  //首页
import refresh from "./views/refresh/refresh.vue";  //刷新
import companyIntroduction from "./views/companyIntroduction/index.vue";  //公司介绍
import managementLayer from "./views/managementLayer/index.vue";  //公司管理层
import greatDeeds from "./views/greatDeeds/index.vue";  //集团大事记
import industry from "./views/industry/index.vue";  //集团产业
import trailer from "./views/trailer/index.vue";  //企业宣传片
import trailerList from "./views/trailer/list.vue";  //按类目检索视频
import brand from "./views/brand/index.vue";  //品牌
import researchInstitute from "./views/researchInstitute/index.vue";  //晨冠科学研究院
import nutritionCenter from "./views/nutritionCenter/index.vue";  //国际营养研究中心
import achievements from "./views/achievements/index.vue";  //研发成果
import transparentFactory from "./views/transparentFactory/index.vue";  //透明工厂
import news from "./views/news/index.vue";  //新闻动态
import newsDetails from "./views/news/details.vue";  //新闻动态详情
import joinUs from "./views/joinUs/index.vue";  //加入我们
import congerzhuangProduct from "./views/congerzhuangProduct/index.vue";  //聪尔壮产品
import product from "./views/product/index.vue";  //产品页面
import junxiaobaoProduct from "./views/junxiaobaoProduct/index.vue";  //菌小宝产品
import ticuiProduct from "./views/ticuiProduct/index.vue";  //媞萃产品
export default[
    {
        path:"/",
        name: "home",
        component: home,
        meta: {
            title: "上海晨冠健康科技集团有限公司"
        }
    },
    {
        path:"/refresh",
        name: "refresh",
        component: refresh,
        meta: {
            title: "上海晨冠健康科技集团有限公司"
        }
    },
		{
		    path:"/companyIntroduction",
		    name: "companyIntroduction",
		    component: companyIntroduction,
		    meta: {
		        title: "公司介绍-上海晨冠健康科技集团有限公司"
		    }
		},
		{
		    path:"/managementLayer",
		    name: "managementLayer",
		    component: managementLayer,
		    meta: {
		        title: "公司管理层-上海晨冠健康科技集团有限公司"
		    }
		},
		{
		    path:"/greatDeeds",
		    name: "greatDeeds",
		    component: greatDeeds,
		    meta: {
		        title: "集团大事记-上海晨冠健康科技集团有限公司"
		    }
		},
		{
		    path:"/industry",
		    name: "industry",
		    component: industry,
		    meta: {
		        title: "集团产业-上海晨冠健康科技集团有限公司"
		    }
		},
		{
		    path:"/trailer",
		    name: "trailer",
		    component: trailer,
		    meta: {
		        title: "企业宣传片-上海晨冠健康科技集团有限公司"
		    }
		},
		{
		    path:"/trailerList",
		    name: "trailerList",
		    component: trailerList,
		    meta: {
		        title: "按品牌检索视频-上海晨冠健康科技集团有限公司"
		    }
		},
		{
		    path:"/brand",
		    name: "brand",
		    component: brand,
		    meta: {
		        title: "品牌-上海晨冠健康科技集团有限公司"
		    }
		},
		{
		    path:"/researchInstitute",
		    name: "researchInstitute",
		    component: researchInstitute,
		    meta: {
		        title: "晨冠科学研究院-上海晨冠健康科技集团有限公司"
		    }
		},
		{
		    path:"/nutritionCenter",
		    name: "nutritionCenter",
		    component: nutritionCenter,
		    meta: {
		        title: "晨冠国际营养研究中心-上海晨冠健康科技集团有限公司"
		    }
		},
		{
		    path:"/achievements",
		    name: "achievements",
		    component: achievements,
		    meta: {
		        title: "研发成果-上海晨冠健康科技集团有限公司"
		    }
		},
		{
		    path:"/transparentFactory",
		    name: "transparentFactory",
		    component: transparentFactory,
		    meta: {
		        title: "透明工厂-上海晨冠健康科技集团有限公司"
		    }
		},
		{
		    path:"/news",
		    name: "news",
		    component: news,
		    meta: {
		        title: "新闻动态-上海晨冠健康科技集团有限公司",
						keepAlive: true,
		    }
		},
		{
		    path:"/newsDetails",
		    name: "newsDetails",
		    component: newsDetails,
		    meta: {
		        title: "新闻动态详情-上海晨冠健康科技集团有限公司"
		    }
		},
		{
		    path:"/joinUs",
		    name: "joinUs",
		    component: joinUs,
		    meta: {
		        title: "加入我们-上海晨冠健康科技集团有限公司"
		    }
		},
		{
		    path:"/congerzhuangProduct",
		    name: "congerzhuangProduct",
		    component: congerzhuangProduct,
		    meta: {
		        title: "聪尔壮产品-上海晨冠健康科技集团有限公司"
		    }
		},
		{
		    path:"/product",
		    name: "product",
		    component: product,
		    meta: {
		        title: "产品页-上海晨冠健康科技集团有限公司"
		    }
		},
		{
		    path:"/junxiaobaoProduct",
		    name: "junxiaobaoProduct",
		    component: junxiaobaoProduct,
		    meta: {
		        title: "菌小宝产品-上海晨冠健康科技集团有限公司",
				keepAlive: true // 需要缓存页面
		    }
		},
		{
		    path:"/ticuiProduct",
		    name: "ticuiProduct",
		    component: ticuiProduct,
		    meta: {
		        title: "媞萃产品-上海晨冠健康科技集团有限公司"
		    }
		}
]
